/* eslint-disable react/prop-types */
import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { gameSettings } from '../conf/settings'
import { wording } from '../wording/wording'

import '../css/globalEffects.scss'

import { settings } from '../redux/actions/index'

import hidePoolState from '../pictures/effect_hidePoolState.png'
import hideTriesRune from '../pictures/effect_hideTriesRune.png'
import hideTriesState from '../pictures/effect_hideTriesState.png'
import increaseVictoryEnergy from '../pictures/effect_increaseVictoryEnergy.png'
import increaseCastCost from '../pictures/effect_increaseCastCost.png'

/**
  * @desc Energy dot
*/

const mapStateToProps = (state) => ({
  addEffect: state.addEffect,
  language: state.settings.language
})

function mapDispatchToProps(dispatch) {
  return {
    settings: (payload) => dispatch(settings(payload))
  }
}

function GlobalEffects({
  addEffect, settings, language
}) {
  const effects = []

  // Remove duplicates
  const dedupEffects = []
  addEffect?.forEach((effect) => {
    if (!dedupEffects.includes(effect) || (effect === 'increaseVictoryEnergy')) {
      dedupEffects.push(effect)
    }
  })

  if (dedupEffects.length > 0) {
    for (let index = 0; index < dedupEffects.length; index += 1) {
      const effect = dedupEffects[index]
      const effectClasses = classNames({
        globalEffect: true,
        [`${effect}`]: effect,
        [`effect_delay_${index + 1}`]: index + 1
      })

      // Invisible effects are not displayed
      if (!gameSettings.hiddenAddEffects.includes(effect)) {
        let picture
        switch (effect) {
          case 'hidePoolState':
            picture = hidePoolState
            break
          case 'hideTriesRune':
            picture = hideTriesRune
            break
          case 'hideTriesState':
            picture = hideTriesState
            break
          case 'increaseVictoryEnergy':
            picture = increaseVictoryEnergy
            break
          case 'increaseCastCost':
            picture = increaseCastCost
            break
          default:
            break
        }

        effects.push(
          <CSSTransition
            key={effect}
            timeout={800}
            classNames="energyVariation"
            appear
          >
            <div
              className={effectClasses}
            >
              <img src={picture} alt={wording[language].effects[effect].title} />
              {wording[language].effects[effect].title}
              <div className="description">
                {wording[language].effects[effect].text.active}
              </div>
            </div>
          </CSSTransition>
        )
      }
    }
  }

  return (
    <TransitionGroup className="globalEffectsWrapper">
      {effects}
    </TransitionGroup>
  )
}

// Exporting as default
export default connect(mapStateToProps, mapDispatchToProps)(GlobalEffects)
