/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import useSound from 'use-sound'

import { setGameState } from '../redux/actions/index'
import { clog } from '../utils/utils'

import '../css/battle.scss'

import RunePool from '../Ui/RunePool'
import RuneGrid from '../Ui/RuneGrid'
import RuneCast from '../Ui/RuneCast'
import Score from '../Ui/Score'

import GameBar from '../Ui/GameBar'
import GlobalEffects from '../Ui/GlobalEffects'

import victory from '../sound/victory.mp3'
import endgame from '../sound/endgame.mp3'
import ActionTokens from '../Ui/ActionTokens'

const mapStateToProps = (state) => ({
  runeSecretFound: state.runeSecretFound,
  gameOver: state.gameOver,
  state: state.state,
  level: state.level,
  aett: state.aett,
  volume: state.settings.volume
})

function mapDispatchToProps(dispatch) {
  return {
    setGameState: (payload) => dispatch(setGameState(payload))
  }
}

const Battle = function ({
  runeSecretFound, setGameState, gameOver, state, aett, volume
}) {
  clog('Play area', 'location')

  const appWrapperClasses = classNames({
    appWrapper: true,
    [`${aett}`]: aett
  })

  // End game / End round
  const [victorySFX] = useSound(victory, { volume })
  const [endgameSFX] = useSound(endgame, { volume })

  let redirect = () => {}
  if (runeSecretFound && state !== 'victory') {
    victorySFX()
    redirect = () => setGameState({ state: 'victory' })
  }
  if (gameOver && state !== 'gameOver') {
    endgameSFX()
    redirect = () => setGameState({ state: 'gameOver' })
  }

  // auto redirect
  if (typeof redirect === 'function') {
    const redirectTimeout = setTimeout(redirect, 1000)
  }

  return [
    <div className="mainWrapper">
      <div
        className={appWrapperClasses}
      >
        <Score />
        <GlobalEffects />
        <ActionTokens />
        <div className="runeGridWrapper">
          <GameBar key="gamebar" />
          <RuneGrid />
        </div>
        <RuneCast />

      </div>
    </div>,
    <RunePool />
  ]
}

export default connect(mapStateToProps, mapDispatchToProps)(Battle)
