/* eslint-disable react/prop-types */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { CSSTransition, SwitchTransition, TransitionGroup } from 'react-transition-group'
import classNames from 'classnames'

// Import redux actions
import { settings, setGameState } from './redux/actions/index'

// Import views
import Welcome from './views/Welcome'
import GameIntro from './views/GameIntro'
import GameStart from './views/GameStart'
import Battle from './views/Battle'
import Victory from './views/Victory'
import GameOver from './views/GameOver'
import About from './views/About'
import Scores from './views/Scores'

// Import UI elements
import RuneCloud from './Ui/RuneCloud'
import Storm from './Ui/Storm'
import Cloud from './Ui/Cloud'
import Volume from './Ui/Volume'
import Characters from './Ui/Characters'
import BattleCharacters from './Ui/BattleCharacters'

import './css/animations.scss'
import './css/mobile.scss'

const mapStateToProps = (state) => ({
  state: state.state,
  previousState: state.previousState,
  aett: state.aett,
  runeSecretFound: state.runeSecretFound
})

function mapDispatchToProps(dispatch) {
  return {
    settings: (payload) => dispatch(settings(payload)),
    setGameState: (payload) => dispatch(setGameState(payload))
  }
}

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    const {
      state, previousState, aett, runeSecretFound
    } = this.props

    let view
    let displayFog
    let displayRuneCloud = false
    let leftCharacter = false
    let rightCharacter = false
    let leftIntroCharacter = false
    let rightIntroCharacter = false

    switch (state) {
      case 'welcome':
        view = <Welcome />
        displayFog = 'fog'
        displayRuneCloud = true
        leftCharacter = 'odin'
        rightCharacter = 'loki'
        leftIntroCharacter = 'tyr'
        rightIntroCharacter = 'heimdall'
        break
      case 'gameIntro':
        view = <GameIntro />
        displayFog = 'fog'
        break
      case 'gameStart':
        view = <GameStart />
        displayFog = 'fog'
        leftCharacter = 'freyr'
        break
      case 'battle':
        view = <Battle />
        displayFog = 'fog'
        break
      case 'victory':
        view = <Victory />
        displayFog = 'fog'
        displayRuneCloud = true
        break
      case 'gameOver':
        view = <GameOver />
        displayFog = 'fog'
        rightCharacter = 'loki'
        break
      case 'gameAbout':
        view = <About />
        displayFog = 'fog'
        break
      case 'gameScores':
        view = <Scores />
        displayFog = 'fog'
        leftCharacter = 'tyr'
        break
      default:
        break
    }

    const appBodyClasses = classNames({
      appBody: true,
      [`${aett}`]: aett
    })

    return (
      <>
        <Volume />
        <Storm />
        <SwitchTransition key="switch">
          <CSSTransition
            key={state}
            timeout={1000}
            classNames="fadeView_1000"
            addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
            appear
          >
            {view}
          </CSSTransition>
        </SwitchTransition>
        <div key="appBody" className={appBodyClasses} />
        <TransitionGroup className="runeCloud" key="runeCloudwrapper">
          {displayRuneCloud && (
            <CSSTransition
              key={`transition_${displayRuneCloud}`}
              timeout={1000}
              classNames="fadeView_1000"
            >
              <RuneCloud />
            </CSSTransition>
          )}
        </TransitionGroup>
        <TransitionGroup className="fogContainer" key="fogwrapper">
          {displayFog === 'fog' && (<Cloud />)}
        </TransitionGroup>
        <Characters dark left={leftCharacter} right={rightCharacter} />
        <Characters small left={leftIntroCharacter} right={rightIntroCharacter} />
        {(state === 'battle' || state === 'victory') && <BattleCharacters />}
      </>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
