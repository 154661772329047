/* eslint-disable no-param-reassign */
import { gameSettings } from '../../conf/settings'
import { resetRound } from '../../utils/utils'
import { generateSaga, generateRunePool, generateSecret } from '../../utils/runes'
import { getSeed } from '../../utils/seed'

/**
  * @desc Start of a new game
*/

const gameStart = (data, mode, bonus) => {
  // Get initial settings
  data.level = 1
  data.score = 0
  // data.mode = mode
  // data.seed = getSeed(mode)
  data.energy = gameSettings.energy
  data.victoryEnergy = gameSettings.victoryEnergy
  data.castCost = gameSettings.castCost
  // Aett preview
  if (bonus) data.aett = bonus
  else delete data.aett
  // Generate runes and secret
  data.runeSaga = generateSaga(data.seed)
  data.secretLength = gameSettings.secretLength
  data.runePool = generateRunePool(data.runeSaga, gameSettings.poolLength)
  data.secret = generateSecret(data.runePool, data.secretLength, data.level, data.seed)
  // Make sure everything is clean
  data.triesCount = 0
  data.gameTriesCount = 0
  data.vegvisir = 0
  data.skogarmaor = 0
  data.njord = 0
  data.yggdrasil = 0
  data = resetRound(data)
  if (data.addEffect) delete data.addEffect
  // if it's a daily game, store the day in the settings
  // if (mode !== 'random') data.settings.daily = data.seed
  // Visual effect
  data.flash = 1
  // Set game state
  data.state = 'gameStart'

  return data
}

export { gameStart }
