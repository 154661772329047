/* eslint-disable no-param-reassign */
import _, { isEqual } from 'underscore'

import { gameSettings } from '../../conf/settings'
import { futhark } from '../../conf/futhark'
import { score } from '../../utils/score'

/**
  * @desc Computing the call of a rune set
*/

const cast = (data) => {
  const {
    currentTry, secret, aett, playedCombos, freeCast
  } = data

  // Compute best state for runes
  if (!data.bestState) data.bestState = {}

  // Store previous energy
  data.previousEnergy = data.energy

  // Parse current try, get states, and update if found state is better
  for (let i = 0; i < currentTry.length; i += 1) {
    let state = 0
    if (!data.bestState[currentTry[i]]) data.bestState[currentTry[i]] = state

    if (currentTry[i] === secret[i]) {
      data = score(data, 'runeFound')
      state = 2
    } else if (secret.includes(currentTry[i])) {
      data = score(data, 'runePresent')
      state = 1
    }
    if (data.bestState[currentTry[i]] < state) data.bestState[currentTry[i]] = state
  }

  // Add try to tries history
  data.tries[data.round] = currentTry

  // Add energy bonus
  let currentCombo = 0
  const currentComboRunes = []
  let bonusEnergy = 0

  for (let index = 0; index < currentTry.length; index += 1) {
    const rune = currentTry[index]
    if (futhark[rune].aett === aett && !playedCombos?.includes(rune)) {
      currentCombo += 1
      currentComboRunes.push(rune)
    }
  }

  // Combos are free + bonus
  if (currentCombo >= gameSettings.minimumCombo) {
    bonusEnergy = currentCombo
    // Add to burned combos
    if (!data.playedCombos) data.playedCombos = []
    data.playedCombos.push(...currentComboRunes)
    // Add score
    data = score(data, 'colorCombo')
  } else if (freeCast) {
    // Cast is free, but destroy token
    data.freeCast = false
  } else {
    // Remove energy cost
    data.energy -= data.castCost + data.runeCastCost
  }

  data.energy += bonusEnergy

  // Energy can't be negative
  if (data.energy < 0) data.energy = 0

  // Did we win ?
  let victory = true
  for (let index = 0; index < secret.length; index++) {
    // Othala is a wildcard
    if (
      currentTry[index] !== 'othala'
      && currentTry[index] !== secret[index]
    ) victory = false
  }

  // if (_.isEqual(currentTry, secret)) {
  if (victory) {
    // Set secret as found
    data.runeSecretFound = true
    // Add victory energy
    const bonusEnergy = (gameSettings.victoryEnergyBonus - data.triesCount) <= 0
      ? 0
      : gameSettings.victoryEnergyBonus - data.triesCount
    data.energy += data.victoryEnergy + bonusEnergy - data.runeCastCost
    // Add score
    data = score(data, 'secretFound')
  } else {
    // Are we done ?
    if (data.energy <= 0) {
      data.gameOver = true
      // black/white effect
      delete data.aett
    } else {
      // Go to next round
      data.round += 1
      // Add score
      data = score(data, 'nextRound')
    }
  }

  // Energy variation
  data.energyVariation = data.energy - data.previousEnergy

  // Update counter
  data.triesCount += 1
  data.gameTriesCount += 1

  // Visual effect
  data.flash = 1

  // Reset current try
  data.currentTry = []
  data.runeCastCost = 0

  return data
}

export { cast }
