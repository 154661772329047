/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { connect } from 'react-redux'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import EnergyDot from './EnergyDot'
import Explanations from './Explanations'
import Token from './Token'

import { gameSettings } from '../conf/settings'
import { playEffect, settings } from '../redux/actions/index'

import '../css/gameBar.scss'

/**
  * @desc New rune display
*/

const mapStateToProps = (state) => ({
  triesCount: state.triesCount,
  secret: state.secret,
  currentTry: state.currentTry,
  castCost: state.castCost,
  runeCastCost: state.runeCastCost,
  playedCombos: state.playedCombos,
  aett: state.aett,
  energy: state.energy,
  victoryEnergy: state.victoryEnergy,
  runeSecretFound: state.runeSecretFound,
  energyVariation: state.energyVariation,
  vegvisir: state.vegvisir,
  skogarmaor: state.skogarmaor,
  yggdrasil: state.yggdrasil,
  njord: state.njord,
  addEffect: state.addEffect,
  state: state.state,
  language: state.settings.language
})

function mapDispatchToProps(dispatch) {
  return {
    playEffect: (payload) => dispatch(playEffect(payload)),
    settings: (payload) => dispatch(settings(payload))
  }
}

function GameBar({
  triesCount, victoryEnergy, runeSecretFound, state
}) {
  const newEnergy = victoryEnergy + (
    gameSettings.victoryEnergyBonus - triesCount + 1 <= 0
      ? 0
      : gameSettings.victoryEnergyBonus - triesCount + 1
  )

  const victoryEnergyDots = []
  // newEnergy - 1 as this is truly effective at the next turn
  for (let index = 0; index < newEnergy - 1; index += 1) {
    if (!runeSecretFound) {
      victoryEnergyDots.push(
        <CSSTransition
          key={`victoryEnergyDot_${index}`}
          timeout={800}
          classNames="energyVariation"
          appear
        >
          <EnergyDot type="bonus" />
        </CSSTransition>
      )
    }
  }

  // Display component
  if (state === 'gameOver' || state === 'welcome') return null
  return (
    <>
      <TransitionGroup className="victoryEnergy">
        {victoryEnergyDots}
      </TransitionGroup>
      <Explanations />
    </>
  )
}

// Exporting as default
export default connect(mapStateToProps, mapDispatchToProps)(GameBar)
