/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { connect } from 'react-redux'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import EnergyDot from './EnergyDot'

import { playEffect, settings } from '../redux/actions/index'

import '../css/gameBar.scss'

/**
  * @desc New rune display
*/

const mapStateToProps = (state) => ({
  secret: state.secret,
  currentTry: state.currentTry,
  castCost: state.castCost,
  runeCastCost: state.runeCastCost,
  aett: state.aett,
  energy: state.energy,
  victoryEnergy: state.victoryEnergy,
  runeSecretFound: state.runeSecretFound,
  energyVariation: state.energyVariation,
  addEffect: state.addEffect,
  state: state.state,
  language: state.settings.language
})

function mapDispatchToProps(dispatch) {
  return {
    playEffect: (payload) => dispatch(playEffect(payload)),
    settings: (payload) => dispatch(settings(payload))
  }
}

function GameBar({
  runeCastCost, energy,
  energyVariation, currentTry, secret,
  state, castCost
}) {
  const energyDots = []

  // Altering energy on display only to better reflect changes
  let displayEnergy = energy

  if (currentTry.length === secret.length) {
    displayEnergy -= castCost
  }
  displayEnergy -= runeCastCost

  for (let index = 0; index < displayEnergy; index += 1) {
    energyDots.push(
      <CSSTransition
        key={`energyDot_${index}`}
        timeout={800}
        classNames={(index >= displayEnergy - energyVariation) ? 'energyVariationPrize' : 'energyVariation'}
        appear
      >
        <EnergyDot type={(index >= displayEnergy - energyVariation) && 'bonus'} />
      </CSSTransition>
    )
  }

  // Display component
  if (state === 'gameOver' || state === 'welcome') return null
  return (
    <div className="energyDotsWrapper">
      <TransitionGroup className="energy">
        {energyDots}
      </TransitionGroup>
    </div>
  )
}

// Exporting as default
export default connect(mapStateToProps, mapDispatchToProps)(GameBar)
