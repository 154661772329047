/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { connect } from 'react-redux'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import useSound from 'use-sound'

import { setGameState, playRune } from '../redux/actions/index'

import RuneButton from './RuneButton'
import RuneSocket from './RuneSocket'
import Energy from './Energy'
import EnergyDot from './EnergyDot'

import tokenNjord from '../pictures/tokenNjord.png'
import '../css/runeCast.scss'
import cast from '../sound/cast.mp3'

/**
  * @desc Wrapper for all the possible runes
*/

const mapStateToProps = (state) => ({
  state: state.state,
  secret: state.secret,
  energy: state.energy,
  castCost: state.castCost,
  runeCastCost: state.runeCastCost,
  currentTry: state.currentTry,
  gameOver: state.gameOver,
  language: state.settings.language,
  aett: state.aett,
  playedCombos: state.playedCombos,
  freeCast: state.freeCast,
  volume: state.settings.volume
})

function mapDispatchToProps(dispatch) {
  return {
    playRune: (payload) => dispatch(playRune(payload)),
    setGameState: (payload) => dispatch(setGameState(payload))
  }
}

function RuneCast({
  currentTry, secret, playRune, setGameState,
  energy, castCost, runeCastCost, gameOver, language, state, aett,
  playedCombos, freeCast, volume
}) {
  const line = []
  const sockets = []

  const runeWidth = 80

  // Played runes
  for (let i = 0; i < secret.length; i++) {
    if (currentTry[i] && !gameOver) {
      line.push(
        <CSSTransition
          key={`${currentTry[i]}_rune_try`}
          timeout={400}
          classNames="runePlayed"
        >
          <RuneButton
            key={`rune_button_cast_${i}`}
            type={currentTry[i]}
            mode="delete"
            xPosition={(currentTry.indexOf(currentTry[i])) * runeWidth}
          />
        </CSSTransition>
      )
    }
    sockets.push(<div key={`rune_button_socket_${i}`} className="socketWrapper"><RuneSocket /></div>)
  }

  // Energy cost
  const energyDots = []

  // Basic energy cost
  for (let index = 0; index < castCost; index += 1) {
    if (currentTry.length === secret.length) {
      energyDots.push(
        <CSSTransition
          key={`energey_castCost_${index}`}
          timeout={400}
          classNames="runePlayed"
        >
          <EnergyDot type={index > 0 && 'fatigue'} key={`energy_castCost_${index}`} />
        </CSSTransition>
      )
    }
  }
  // Rune cast cost
  for (let index = 0; index < runeCastCost; index += 1) {
    energyDots.push(
      <CSSTransition
        key={`energey_runeCastCost_${index}`}
        timeout={400}
        classNames="runePlayed"
      >
        <EnergyDot type="penalty" key={`energy_runeCastCost_${index}`} />
      </CSSTransition>
    )
  }

  // Width of the try container
  const lineWrapperStyle = {
    justifyContent: 'flex-start',
    width: `${secret.length * runeWidth}px`
  }

  const [castRuneSFX] = useSound(cast, { volume })

  // Display component
  return (
    <div className="runeCast">
      <div key="runeLineExternalWrapper" className="runeLineExternalWrapper">
        <div key="runeLineWrapper" className="runeLineWrapper" style={lineWrapperStyle}>
          <TransitionGroup key="currentTry" className="currentTry">
            {line}
          </TransitionGroup>
          <div className="currentTrySockets">{sockets}</div>
        </div>
        <Energy />
        <div
          key="callButton"
          role="button"
          className={`
          callButton 
          ${aett} 
          ${(energyDots.length !== 0 || freeCast) && 'armed'}
          ${(currentTry.length === secret.length) && 'ready'}
        `}
          onMouseDown={castRuneSFX}
          onClick={(currentTry.length === secret.length) ? (() => playRune({ mode: 'cast' })) : undefined}
        >
          <TransitionGroup key="energyCost" className="energyContainer">
            {freeCast
              ? (
                <CSSTransition
                  key="energey_runeCastCost_free"
                  timeout={400}
                  classNames="runePlayed"
                >
                  <img key="tokenNjord" className="token" src={tokenNjord} alt="Njord" />
                </CSSTransition>
              )
              : energyDots}
          </TransitionGroup>
        </div>
      </div>
    </div>
  )
}

// Exporting as default
export default connect(mapStateToProps, mapDispatchToProps)(RuneCast)
