/* eslint-disable react/prop-types */
import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { randomBetween } from '../utils/utils'

import '../css/energyDot.scss'
import energy from '../pictures/energy.png'
import energyPenalty from '../pictures/energyPenalty.png'
import runeCastCost from '../pictures/runeCastCost.png'
import fatiguePenalty from '../pictures/energyPenaltyCastCost.png'
import energyBonus from '../pictures/energyBonus.png'

/**
  * @desc Energy dot
*/

const mapStateToProps = (state) => ({

})

function EnergyDot({
  type
}) {
  const dotClasses = classNames({
    energyDot: true,
    [`${type}`]: type
  })

  const dotStyle = (type === 'cost' || type === 'energyBonus')
    ? {}
    : {
      transform: `rotate(${Math.round(randomBetween(-10, 15))}deg)`,
      top: `${Math.round(randomBetween(-2, 2))}px`,
      left: `${Math.round(randomBetween(-2, 2))}px`
    }

  function getPicture(mode) {
    switch (mode) {
      case 'bonus':
        return energyBonus
      case 'fatigue':
        return fatiguePenalty
      case 'penalty':
        return energyPenalty
      case 'cost':
        return runeCastCost
      default:
        return energy
    }
  }

  return (
    <div className={dotClasses}>
      <img src={getPicture(type)} alt="Energy" style={dotStyle} />
    </div>
  )
}

// Exporting as default
export default connect(mapStateToProps)(EnergyDot)
