/* eslint-disable react/no-unknown-property */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { connect } from 'react-redux'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import classNames from 'classnames'

import { setGameState, settings } from '../redux/actions/index'

import fog from '../pictures/fog.png'
import smoke from '../pictures/smoke.png'
import '../css/storm.scss'

/**
  * @desc Animated fog
*/

const mapStateToProps = (state) => ({
  state: state.state,
  aett: state.aett,
  flash: state.flash
})

function mapDispatchToProps(dispatch) {
  return {
    setGameState: (payload) => dispatch(setGameState(payload)),
    settings: (payload) => dispatch(settings(payload))
  }
}

const Cloud = function ({
  flash, settings, aett
}) {
  function castFlash(flashValue) {
    settings({
      setting: 'flash',
      flash: flashValue
    })
  }

  const fogWrapperClasses = classNames({
    fogWrapper: true,
    [`${aett}`]: aett
  })

  return (
    <CSSTransition
      key="transition_fog"
      timeout={1000}
      classNames="slideView_1000"
    >
      <div
        className={fogWrapperClasses}
        flash={flash}
        onAnimationEnd={() => castFlash(0)}
      >
        <img className="smoke" key="smoke" src={smoke} alt="" />
        <img className="fog" key="fog" src={fog} alt="" />
      </div>
    </CSSTransition>
  )
}

// Exporting as default
export default connect(mapStateToProps, mapDispatchToProps)(Cloud)
