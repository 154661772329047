/* eslint-disable react/button-has-type */
import React from 'react'
import { connect } from 'react-redux'
import useSound from 'use-sound'

import { setGameState } from '../redux/actions/index'
import { clog } from '../utils/utils'
import { wording } from '../wording/wording'

import Title from '../Ui/Title'

import '../css/app.scss'
import '../css/gameOver.scss'

import splash from '../pictures/splash.png'
import sigilTyr from '../pictures/sigilTyr.png'

import menuSFX1 from '../sound/menu.mp3'
import menuHoverSFX1 from '../sound/menuHover.mp3'

const mapStateToProps = (state) => ({
  language: state.settings.language,
  level: state.level,
  stats: state.stats,
  volume: state.settings.volume
})

function mapDispatchToProps(dispatch) {
  return {
    setGameState: (payload) => dispatch(setGameState(payload))
  }
}

const About = function ({
  setGameState, language, level, stats, volume
}) {
  clog('About the game', 'location')

  const [menuSFX] = useSound(menuSFX1, { volume })
  const [menuHoverSFX] = useSound(menuHoverSFX1, { volume })

  return (
    <div className="mainWrapper">
      <div className="appWrapper welcome">
        <Title
          title={`${wording[language].gameAbout.title} ${wording[language].appName}`}
          subtitle={wording[language].gameAbout.description}
        />
        <img src={splash} alt="Ultimate manatee studio" />
        <div className="credits">
          <div className="creditItem">
            <span className="title">{wording[language].gameAbout.other}</span>
            <a href="https://mhd20.com" target="_blank" rel="noreferrer" style={{ color: 'white' }}>Monster Hunter D20</a>
          </div>
          <div className="creditItem">
            <span className="title">{wording[language].gameAbout.contact}</span>
            remi
            <br />
            @umstudio.fr
          </div>
          <div className="creditItem">
            <span className="title">{wording[language].gameAbout.assets}</span>
            <a href="https://itch.io/profile/medievalmore" target="_blank" rel="noreferrer" style={{ color: 'white' }}>MedievalMore</a>
            <a href="https://franuka.itch.io/" target="_blank" rel="noreferrer" style={{ color: 'white' }}>Franuka</a>
          </div>
        </div>
        <button
          className="actionButton"
          onMouseDown={menuSFX}
          onMouseEnter={() => menuHoverSFX()}
          onClick={() => setGameState({ state: 'reset' })}
        >
          <img
            src={sigilTyr}
            className="big"
            alt="Ranked Game"
          />
          <div className="description">
            <div className="title">
              {wording[language].ui.play}
            </div>
            <div className="subtitle">
              {wording[language].ui.backToMenu}
            </div>
          </div>
        </button>
      </div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(About)
