/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { connect } from 'react-redux'
import useSound from 'use-sound'

import { setGameState, settings } from '../redux/actions/index'
import { clog } from '../utils/utils'
import { wording } from '../wording/wording'

import '../css/app.scss'
import '../css/gameStart.scss'

import tokenVegvisir from '../pictures/tokenVegvisir.png'
import tokenKnot from '../pictures/tokenKnot.png'
import tokenYggdrasil from '../pictures/tokenYggdrasil.png'
import tokenNjord from '../pictures/tokenNjord.png'

import startgame from '../sound/startgame.mp3'
import menuHoverSFX1 from '../sound/menuHover.mp3'

const mapStateToProps = (state) => ({
  state: state.state,
  mode: state.mode,
  language: state.settings.language,
  volume: state.settings.volume
})

function mapDispatchToProps(dispatch) {
  return {
    setGameState: (payload) => dispatch(setGameState(payload)),
    settings: (payload) => dispatch(settings(payload))
  }
}

const GameStart = function ({
  state, mode, setGameState, settings, language, volume
}) {
  clog('Battle intro', 'location')

  const [menuSFX] = useSound(startgame, { volume })
  const [menuHoverSFX] = useSound(menuHoverSFX1, { volume })

  function setStartAett(aett) {
    if (aett) menuHoverSFX()
    setGameState({ state: 'gameStart', mode })
  }

  function startGame(aett) {
    setGameState({ state: 'battle', bonus: aett })
    settings({
      setting: 'message',
      message: null
    })
  }

  return (
    <div className="mainWrapper">
      <div className="appWrapper">
        <div className="startWrapper">
          <div className="titleBlock">
            <div className="title">{wording[language].ui.equipment}</div>
            <div className="subtitle">{wording[language].ui.selectEquipment}</div>
          </div>
          <div
            className="actionButton"
            onMouseEnter={state === 'gameStart' ? (() => setStartAett('tyr')) : undefined}
            onMouseLeave={state === 'gameStart' ? (() => setStartAett(null)) : undefined}
            onMouseDown={menuSFX}
            onClick={state === 'gameStart' ? (() => startGame('tyr')) : undefined}
          >
            <img
              src={tokenVegvisir}
              alt="Vegvisir"
            />
            <div className="description">
              <div className="title">{wording[language].effects.vegvisir.title}</div>
              <div className="subtitle">{wording[language].effects.vegvisir.text.active}</div>
            </div>
          </div>

          <div
            className="actionButton"
            onMouseEnter={state === 'gameStart' ? (() => setStartAett('heimdall')) : undefined}
            onMouseLeave={state === 'gameStart' ? (() => setStartAett(null)) : undefined}
            onMouseDown={menuSFX}
            onClick={state === 'gameStart' ? (() => startGame('heimdall')) : undefined}
          >
            <img
              src={tokenKnot}
              alt="Knot"
            />
            <div className="description">
              <div className="title">{wording[language].effects.skogarmaor.title}</div>
              <div className="subtitle">{wording[language].effects.skogarmaor.text.active}</div>
            </div>
          </div>

          <div
            className="actionButton"
            onMouseEnter={state === 'gameStart' ? (() => setStartAett('freyr')) : undefined}
            onMouseLeave={state === 'gameStart' ? (() => setStartAett(null)) : undefined}
            onMouseDown={menuSFX}
            onClick={state === 'gameStart' ? (() => startGame('freyr')) : undefined}
          >
            <img
              src={tokenYggdrasil}
              alt="Yggdrasil"
            />
            <div className="description">
              <div className="title">{wording[language].effects.yggdrasil.title}</div>
              <div className="subtitle">{wording[language].effects.yggdrasil.text.active}</div>
            </div>
          </div>

          <div
            className="actionButton"
            onMouseEnter={state === 'gameStart' ? (() => setStartAett('njord')) : undefined}
            onMouseLeave={state === 'gameStart' ? (() => setStartAett(null)) : undefined}
            onMouseDown={menuSFX}
            onClick={state === 'gameStart' ? (() => startGame('njord')) : undefined}
          >
            <img
              src={tokenNjord}
              alt="Njord"
            />
            <div className="description">
              <div className="title">{wording[language].effects.njord.title}</div>
              <div className="subtitle">{wording[language].effects.njord.text.active}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(GameStart)
