/* eslint-disable no-param-reassign */
/**
  * @desc Resetting game to title screen
*/

const welcome = (data, bonus) => {
  // Aett preview
  if (bonus) data.aett = bonus
  else delete data.aett
  // Set game state
  data.state = 'welcome'
  // Visual effect
  data.flash = 1

  return data
}

export { welcome }
