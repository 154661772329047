/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable react/prop-types */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import useSound from 'use-sound'

import { setGameState, settings } from '../redux/actions/index'
import { clog } from '../utils/utils'
import { wording } from '../wording/wording'

import Title from '../Ui/Title'

import '../css/app.scss'
import '../css/gameIntro.scss'

import backpack from '../pictures/backpack.png'

import menuSFX1 from '../sound/menu.mp3'
import menuHoverSFX1 from '../sound/menuHover.mp3'

const mapStateToProps = (state) => ({
  state: state.state,
  mode: state.mode,
  name: state.settings.name,
  language: state.settings.language,
  volume: state.settings.volume
})

function mapDispatchToProps(dispatch) {
  return {
    setGameState: (payload) => dispatch(setGameState(payload)),
    settings: (payload) => dispatch(settings(payload))
  }
}

const GameIntro = function ({
  state, setGameState, language, volume, name, settings
}) {
  clog('Game intro intro', 'location')

  function startGame() {
    setGameState({ state: 'gameStart' })
  }

  const handleChange = (event) => {
    settings({
      setting: 'name',
      name: event.target.value
    })
  }

  const startButtonClasses = classNames({
    actionButton: true,
    disabled: !name || name.length < 3
  })

  const [menuSFX] = useSound(menuSFX1, { volume })
  const [menuHoverSFX] = useSound(menuHoverSFX1, { volume })

  return (
    <div className="mainWrapper">
      <div className="appWrapper">
        <div className="introWrapper">
          <Title
            title={wording[language].ui.whatsYourName}
            subtitle={wording[language].ui.whatsYourNameSub}
          />
          <input
            type="text"
            minLength="3"
            maxLength="15"
            id="playerNameInput"
            name="playerName"
            value={name}
            placeholder={wording[language].ui.yourName}
            onChange={handleChange}
            autoComplete="off"
            autoFocus
          />
          <button
            className={startButtonClasses}
            onMouseDown={menuSFX}
            onMouseEnter={() => menuHoverSFX()}
            onClick={(state === 'gameIntro' && (name && name.length > 3))
              ? (() => startGame())
              : undefined}
          >
            <img
              src={backpack}
              className="big"
              alt="Select equipment"
            />
            <div className="description">
              <div className="title">
                {wording[language].ui.startGame}
              </div>
              <div className="subtitle">
                {wording[language].ui.selectEquipment}
              </div>
            </div>
          </button>
          <button
            className="secondaryButton"
            onMouseDown={menuSFX}
            onMouseEnter={() => menuHoverSFX()}
            onClick={() => setGameState({ state: 'reset' })}
          >
            {wording[language].ui.backToMenu}
          </button>
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(GameIntro)
