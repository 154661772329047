/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { connect } from 'react-redux'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import classNames from 'classnames'
import useSound from 'use-sound'

import { setGameState, settings } from '../redux/actions/index'

import { futhark } from '../conf/futhark'
import { wording } from '../wording/wording'

import '../css/newRune.scss'

import RuneButton from './RuneButton'

import tokenVegvisir from '../pictures/tokenVegvisir.png'
import tokenKnot from '../pictures/tokenKnot.png'
import tokenNjord from '../pictures/tokenNjord.png'

import sigilTyr from '../pictures/sigilTyr.png'
import sigilFreyr from '../pictures/sigilFreyr.png'
import sigilHeimdall from '../pictures/sigilHeimdall.png'

import longerSecret from '../pictures/longerSecret.png'
import longerSecretPlus from '../pictures/longerSecretPlus.png'
import shorterSecret from '../pictures/shorterSecret.png'
import runeCastCost from '../pictures/runeCastCost.png'
import reveal from '../pictures/reveal.png'
import joker from '../pictures/joker.png'
import hidden from '../pictures/hidden.png'

import hidePoolState from '../pictures/effect_hidePoolState.png'
import hideTriesRune from '../pictures/effect_hideTriesRune.png'
import hideTriesState from '../pictures/effect_hideTriesState.png'
import increaseVictoryEnergy from '../pictures/effect_increaseVictoryEnergy.png'
import increaseCastCost from '../pictures/effect_increaseCastCost.png'

import nextRuneSFX1 from '../sound/nextRune.mp3'
import menuHoverSFX1 from '../sound/menuHover.mp3'

/**
  * @desc New rune display
*/

const mapStateToProps = (state) => ({
  newRune: state.newRune,
  addEffect: state.addEffect,
  state: state.state,
  volume: state.settings.volume,
  language: state.settings.language,
  secretLength: state.secretLength
})

function mapDispatchToProps(dispatch) {
  return {
    setGameState: (payload) => dispatch(setGameState(payload)),
    settings: (payload) => dispatch(settings(payload))
  }
}

function NewRune({
  newRune, language, state, setGameState, secretLength, volume
}) {
  // Display effects
  const effects = []
  const displayEffects = []

  // Cast effect
  if (futhark[newRune].castEffect) {
    for (let index = 0; index < futhark[newRune].castEffect.length; index += 1) {
      effects.push(futhark[newRune].castEffect[index])
    }
  }

  // Permanent effects
  if (futhark[newRune].addEffect) {
    for (let index = 0; index < futhark[newRune].addEffect.length; index += 1) {
      let effect = futhark[newRune].addEffect[index]
      // Special case : shorter becomes longer when secret is already small
      if (effect === 'shorterSecret' && secretLength === 2 && state === 'victory') effect = 'longerSecret'
      if (effect === 'shorterSecret' && secretLength === 3 && state === 'battle') effect = 'longerSecret'
      effects.push(effect)
    }
  }

  for (let index = 0; index < effects.length; index += 1) {
    const effect = effects[index]
    let img
    let size
    let trad = effect
    let special = false
    let displayQty = false

    switch (effect) {
      case 'shorterSecret':
        img = shorterSecret
        break
      case 'longerSecret':
        img = longerSecret
        break
      case 'longerSecretPlus':
        img = longerSecretPlus
        break
      case 'removeEffects':
        img = tokenVegvisir
        trad = 'vegvisir'
        size = 'big'
        displayQty = true
        break
      case 'outCast':
        img = tokenKnot
        trad = 'skogarmaor'
        size = 'big'
        displayQty = true
        break
      case 'increaseVictoryEnergy':
        img = increaseVictoryEnergy
        break
      case 'increaseCastCost':
        img = increaseCastCost
        break
      case 'freeCast':
        img = tokenNjord
        trad = 'njord'
        size = 'big'
        displayQty = true
        break
      case 'hideTriesState':
        img = hideTriesState
        break
      case 'hidePoolState':
        img = hidePoolState
        break
      case 'hideTriesRune':
        img = hideTriesRune
        break
      case 'runeCastCost':
        img = runeCastCost
        break
      case 'reveal':
        img = reveal
        break
      case 'joker':
        img = joker
        special = true
        break
      case 'hidden':
        img = hidden
        special = true
        break

      default:
        break
    }

    displayEffects.push(
      <div key={`effect_${effect}`} className={`actionButton inactive ${special ? 'special' : 'normal'}`}>
        <div className="buttonPicture">
          <img
            src={img}
            className={size}
            alt={effect}
          />
          {displayQty && <div className="qty">+1</div>}
        </div>
        <div className="description">
          <div className="title">{wording[language].effects[trad].title}</div>
          <div className="subtitle">{wording[language].effects[trad].text.active}</div>
        </div>
      </div>
    )
  }

  const runeClasses = classNames({
    newRune: true,
    [`${futhark[newRune].aett}`]: true
  })

  // Button picture
  let buttonPicture
  switch (futhark[newRune].aett) {
    case 'tyr':
    case 'odin':
      buttonPicture = sigilTyr
      break
    case 'heimdall':
    case 'loki':
      buttonPicture = sigilHeimdall
      break
    default:
      buttonPicture = sigilFreyr
      break
  }

  const [nextRuneSFX] = useSound(nextRuneSFX1, { volume })
  const [menuHoverSFX] = useSound(menuHoverSFX1, { volume })

  return (
    <TransitionGroup className="newRuneWrapper">
      <CSSTransition
        key={`transition_${newRune}`}
        timeout={1500}
        classNames="zoomView_1000"
        appear
      >
        <div
          className={runeClasses}
        >
          <div className="runeDemoWrapper">
            <h1>{newRune}</h1>
            <div className="runeDemo">
              <RuneButton
                type={newRune}
              />
            </div>
          </div>

          <div className="addEffects">
            {displayEffects}
          </div>

          <button
            className="actionButton"
            onMouseEnter={() => menuHoverSFX()}
            onMouseDown={nextRuneSFX}
            onClick={state === 'victory' ? (() => setGameState({ state: 'nextRound' })) : undefined}
          >
            <img
              src={buttonPicture}
              className="big"
              alt="Next level"
            />
            <div className="description">
              <div className="title">
                {wording[language].ui.nextLevel}
              </div>
              <div className="subtitle">
                {`${wording[language].ui.newRune} : ${newRune}`}
              </div>
            </div>
          </button>
        </div>
      </CSSTransition>
    </TransitionGroup>
  )
}

// Exporting as default
export default connect(mapStateToProps, mapDispatchToProps)(NewRune)
