import {
  GAMESTATE, SETTINGS, PLAYRUNE, PLAYEFFECT, SCORE
} from '../constants/action-types'

function settings(payload) {
  return { type: SETTINGS, payload }
}

function setGameState(payload) {
  return { type: GAMESTATE, payload }
}

function playRune(payload) {
  return { type: PLAYRUNE, payload }
}

function playEffect(payload) {
  return { type: PLAYEFFECT, payload }
}

function updateScore(payload) {
  return { type: SCORE, payload }
}

export {
  settings,
  setGameState,
  playRune,
  playEffect,
  updateScore
}
