/**
  * @desc Game end
*/

const gameOver = (data) => {
  // Set game state
  data.state = 'gameOver'
  // Delete color
  if (data.aett) delete data.aett
  // Store score and stats
  if (!data.stats) data.stats = {}
  if (!data.stats.games) data.stats.games = []
  data.stats.games.push({
    mode: data.mode,
    seed: data.seed,
    level: data.level,
    tries: data.gameTriesCount,
    score: data.score,
    time: Date.now()
  })

  return data
}

export { gameOver }
