/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { playRune } from '../redux/actions/index'
import { hostileFamily } from '../utils/hostileFamily'
import { futhark } from '../conf/futhark'

import '../css/rune.scss'

import sigilTyr from '../pictures/sigilTyr.png'
import sigilFreyr from '../pictures/sigilFreyr.png'
import sigilHeimdall from '../pictures/sigilHeimdall.png'

/**
  * @desc Main rune button
*/

const propTypes = {
  type: PropTypes.string,
  currentSecret: PropTypes.string,
  hideLabel: PropTypes.bool,
  hideState: PropTypes.bool,
  xPosition: PropTypes.number,
  yPosition: PropTypes.number,
  socket: PropTypes.bool,
  mode: PropTypes.oneOf(['add', 'delete', 'display'])
}

const defaultProps = {
  type: null,
  mode: 'add',
  hideLabel: false,
  hideState: false,
  xPosition: undefined,
  yPosition: undefined,
  socket: false,
  currentSecret: null
}

const mapStateToProps = (state) => ({
  secret: state.secret,
  currentTry: state.currentTry,
  bestState: state.bestState,
  aett: state.aett,
  playedCombos: state.playedCombos
})

function mapDispatchToProps(dispatch) {
  return {
    playRune: (payload) => dispatch(playRune(payload))
  }
}

function RuneButton({
  type, aett
}) {
  const runeClasses = classNames({
    rune: true,
    dummy: true,
    [`${futhark[type]?.aett}`]: true
  })

  const runeStyle = {
    transform: `rotate(${futhark[type]?.rotation}deg)`, // More  style={{}} feel for rune position
    animationDuration: `${futhark[type]?.glowDuration}s` // More natural feel for rune glow
  }

  const shimmerStyle = {
    animationDelay: `${futhark[type]?.shimmerDelay}s`,
    animationDuration: `${futhark[type]?.shimmerDuration}s`
  }

  // Additional aett sigil
  let sigil
  switch (futhark[type]?.aett) {
    case 'tyr':
      sigil = (
        <div
          key="sigil"
          className={`sigil tyr ${hostileFamily(futhark[type].aett, aett) ? 'hostile' : 'notHostile'}`}
          style={{ animationDuration: `${futhark[type]?.glowDuration}s` }}
        >
          <img src={sigilTyr} alt="Tyr" />
        </div>
      )
      break
    case 'freyr':
      sigil = (
        <div
          key="sigil"
          className={`sigil freyr ${hostileFamily(futhark[type].aett, aett) ? 'hostile' : 'notHostile'}`}
          style={{ animationDuration: `${futhark[type]?.glowDuration}s` }}
        >
          <img src={sigilFreyr} alt="Freyr" />
        </div>
      )
      break
    case 'heimdall':
      sigil = (
        <div
          key="sigil"
          className={`sigil heimdall ${hostileFamily(futhark[type].aett, aett) ? 'hostile' : 'notHostile'}`}
          style={{ animationDuration: `${futhark[type]?.glowDuration}s` }}
        >
          <img src={sigilHeimdall} alt="Heimdall" />
        </div>
      )
      break
    default:
      break
  }

  // Display component
  return (
    <div key="runeWrapper" className="runeWrapper">
      <TransitionGroup key="runeWrapperTransitionGroup" component={null}>
        <CSSTransition
          key={`${type}_rune`}
          timeout={400}
        >
          <div className="buttonWrapper">
            <button
              className={runeClasses}
              type="button"
              style={runeStyle}
            >
              <div className="shimmer" style={shimmerStyle} />
              {futhark[type]?.letter}
              {sigil}
            </button>
          </div>
        </CSSTransition>
      </TransitionGroup>
    </div>
  )
}

// Applying propTypes definition and default values
RuneButton.propTypes = propTypes
RuneButton.defaultProps = defaultProps

// Exporting as default
export default connect(mapStateToProps, mapDispatchToProps)(RuneButton)
