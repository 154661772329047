import {
  GAMESTATE, SETTINGS, PLAYRUNE, PLAYEFFECT, SCORE
} from '../constants/action-types'

// Game system
import { welcome } from '../../actions/game/welcome'
import { battle } from '../../actions/game/battle'
import { gameIntro } from '../../actions/game/gameIntro'
import { gameStart } from '../../actions/game/gameStart'
import { victory } from '../../actions/game/victory'
import { nextRound } from '../../actions/game/nextRound'
import { gameOver } from '../../actions/game/gameOver'
import { gameScores } from '../../actions/game/gameScores'
import { gameAbout } from '../../actions/game/gameAbout'
import { reset } from '../../actions/game/reset'
import { updateScore } from '../../actions/game/updateScore'
// Game moves
import { playRune } from '../../actions/play/playRune'
import { deleteRune } from '../../actions/play/deleteRune'
import { cast } from '../../actions/play/cast'
import { playEffect } from '../../actions/play/playEffect'
// Addtional features
import { message } from '../../actions/settings/message'
import { flash } from '../../actions/settings/flash'
import { volume } from '../../actions/settings/volume'
import { name } from '../../actions/settings/name'
import { submitScore } from '../../actions/settings/submitScore'
import { preference } from '../../actions/settings/preference'
// Utils
import { clog } from '../../utils/utils'
// Initial state
import { initialState } from '../../conf/settings'
import { saveState } from '../localStorage'

function rootReducer(state = initialState, action) {
  // Prepare next state
  let nextState = JSON.parse(JSON.stringify(state))

  if (action.type === SETTINGS) {
    switch (action.payload.setting) {
      case 'message':
        clog('message', 'reducer')
        nextState = message(nextState, action.payload.message)
        break
      case 'flash':
        clog('flash', 'reducer')
        nextState = flash(nextState, action.payload.flash)
        break
      case 'volume':
        clog('volume', 'reducer')
        nextState = volume(nextState, action.payload.volume)
        break
      case 'name':
        clog('name', 'reducer')
        nextState = name(nextState, action.payload.name)
        break
      case 'submitScore':
        clog('submitScore', 'reducer')
        nextState = submitScore(nextState)
        break
      case 'preference':
        clog('preference', 'reducer')
        nextState = preference(nextState, action.payload.type, action.payload.value)
        break
      default:
        break
    }
  }

  if (action.type === GAMESTATE) {
    // Store page for origin use
    nextState.previousState = nextState.state

    switch (action.payload.state) {
      case 'welcome':
        clog('welcome', 'reducer')
        nextState = welcome(nextState, action.payload.bonus)
        break
      case 'gameIntro':
        clog('gameIntro', 'reducer')
        nextState = gameIntro(nextState, action.payload.mode, action.payload.bonus)
        break
      case 'gameStart':
        clog('gameStart', 'reducer')
        nextState = gameStart(nextState, action.payload.mode, action.payload.bonus)
        break
      case 'battle':
        clog('battle', 'reducer')
        nextState = battle(nextState, action.payload.bonus)
        break
      case 'victory':
        clog('victory', 'reducer')
        nextState = victory(nextState)
        break
      case 'nextRound':
        clog('nextRound', 'reducer')
        nextState = nextRound(nextState)
        break
      case 'gameOver':
        clog('gameOver', 'reducer')
        nextState = gameOver(nextState)
        break
      case 'reset':
        clog('reset', 'reducer')
        nextState = reset(nextState)
        break
      case 'gameAbout':
        clog('gameAbout', 'reducer')
        nextState = gameAbout(nextState)
        break
      case 'gameScores':
        clog('gameScores', 'reducer')
        nextState = gameScores(nextState)
        break
      default:
        clog('error', 'reducer')
        break
    }
  }

  if (action.type === PLAYRUNE) {
    switch (action.payload.mode) {
      case 'play':
        clog('Play a rune', 'reducer')
        nextState = playRune(nextState, action.payload.rune)
        break
      case 'delete':
        clog('Delete a rune', 'reducer')
        nextState = deleteRune(nextState, action.payload.rune)
        break
      case 'cast':
        clog('Cast a guess', 'reducer')
        nextState = cast(nextState)
        break
      default:
        clog('error', 'reducer')
        break
    }

    // Return updated state
    return nextState
  }

  if (action.type === PLAYEFFECT) {
    switch (action.payload.mode) {
      case 'play':
        clog('Play an effect', 'reducer')
        nextState = playEffect(nextState, action.payload.effect)
        break
      default:
        clog('error', 'reducer')
        break
    }

    // Return updated state
    return nextState
  }

  if (action.type === SCORE) {
    switch (action.payload.mode) {
      case 'updateScore':
        nextState = updateScore(nextState, action.payload.effect)
        break
      default:
        clog('error', 'reducer')
        break
    }

    // Return updated state
    return nextState
  }

  // Update LocalStorage
  saveState(nextState)

  // Return updated state
  return nextState
}

export default rootReducer
