/* eslint-disable no-param-reassign */
import { resetRound } from '../../utils/utils'
import { generateSecret } from '../../utils/runes'
import { futhark } from '../../conf/futhark'
import { addFx } from '../../utils/effects'

/**
  * @desc Going to next round
*/

const nextRound = (data) => {
  data.level += 1
  // Play rune add effect
  // if (data.addEffect) delete data.addEffect
  if (futhark[data.newRune].addEffect) data = addFx(data, futhark[data.newRune].addEffect, 'add')
  // Generate a new secret
  data.secret = generateSecret(data.runePool, data.secretLength, data.level, data.seed)
  // Make sure everything is clean
  data = resetRound(data)
  // Visual effect
  data.flash = 1
  // Set game state
  data.state = 'battle'

  return data
}

export { nextRound }
