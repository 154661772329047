/* eslint-disable react/prop-types */
import React from 'react'
import { connect } from 'react-redux'

/**
  * @desc Simple title block
*/

const mapStateToProps = (state) => ({

})

function Title({
  title, subtitle
}) {
  return (
    <div className="titleBlock">
      <div className="title">{title}</div>
      <div className="subtitle">{subtitle}</div>
    </div>
  )
}

// Exporting as default
export default connect(mapStateToProps)(Title)
