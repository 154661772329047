/* eslint-disable no-param-reassign */

/**
  * @desc Set volume
*/

const volume = (data, volumeValue) => {
  const { } = data

  if (volumeValue || volumeValue === 0) {
    data.settings.volume = volumeValue
  }

  return data
}

export { volume }
