export const wording = {
  fr: {
    appName: 'ORD',
    baseline: 'Le secret du futhark',
    ui: {
      dailyGame: 'Partie du jour',
      dailyGameSubtitle: 'Un seul essai',
      trainingGame: 'Jeu libre',
      trainingGameSubtitle: 'Partie aléatoire',
      endGame: 'Partie terminée',
      backToMenu: 'Retour au menu',
      startAgain: 'Recommencer',
      startGame: 'Commencer',
      nextLevel: 'Niveau suivant',
      newRune: 'Nouvelle rune',
      equipment: 'Objet de départ',
      selectEquipment: 'Choisis un objet pour t\'aider',
      level: 'Niveau',
      mode: 'Mode',
      random: 'Libre',
      daily: 'Quotidien',
      play: 'Jouer',
      name: 'Nom',
      date: 'Date',
      seed: 'Seed',
      tries: 'Essais',
      score: 'Score',
      tomorrow: 'A demain!',
      about: 'A propos',
      yourName: 'Ton nom',
      whatsYourName: 'Quel est ton nom?',
      whatsYourNameSub: 'Tes exploits ne seront pas oubliés',
      scores: 'Tableau des scores',
      noScores: 'Pas encore de score',
      viewScores: 'Les meilleurs scores'
    },
    gameAbout: {
      title: 'À propos de',
      description: 'Un incroyable clone du master mind',
      design: 'Design & Dev',
      other: 'Autre jeu',
      assets: 'Icônes',
      contact: 'Contact'
    },
    effects: {
      shorterSecret: {
        title: 'Secret plus court',
        text: {
          active: "Le secret se réduit d'une rune (minimum : 2)"
        }
      },
      shorterSecretPlus: {
        title: 'Secret encore plus court',
        text: {
          active: 'Le secret se réduit de deux runes (minimum : 2)'
        }
      },
      longerSecret: {
        title: 'Secret plus long',
        text: {
          active: "Le secret s'allonge d'une rune"
        }
      },
      longerSecretPlus: {
        title: 'Secret encore plus long',
        text: {
          active: "Le secret s'allonge de deux runes"
        }
      },
      increaseVictoryEnergy: {
        title: 'Energie gagnée + 1',
        text: {
          active: "Chaque victoire rapporte un point d'énergie de plus"
        }
      },
      hideTriesState: {
        title: 'Couleurs oubliées',
        text: {
          active: 'Les couleurs ne sont plus visibles sur les coups joués'
        }
      },
      hideTriesRune: {
        title: 'Runes oubliées',
        text: {
          active: 'Les runes ne sont plus visibles sur les coups joués'
        }
      },
      hidePoolState: {
        title: 'Mémoire courte',
        text: {
          active: "Les runes en main ne donnent plus d'indice"
        }
      },
      increaseCastCost: {
        title: 'Fatigue',
        text: {
          active: "Les essais coûtent un point d'énergie de plus"
        }
      },
      vegvisir: {
        title: 'Remise à zéro',
        text: {
          active: 'Annule tous les effets en cours',
          inactive: 'Annule tous les effets en cours (aucun effet en cours)'
        }
      },
      yggdrasil: {
        title: 'Potion d\'énergie',
        text: {
          active: "Gagne 5 points d'énergie",
          inactive: "Gagne 5 points d'énergie"
        }
      },
      njord: {
        title: 'Essai gratuit',
        text: {
          active: 'Le prochain essai ne coûte rien',
          inactive: 'Le prochain essai ne coûte rien'
        }
      },
      skogarmaor: {
        title: 'Raccourci',
        text: {
          active: 'Passe directement au niveau suivant',
          inactive: 'Passe directement au niveau suivant'
        }
      },
      runeCastCost: {
        title: 'Rune hostile',
        text: {
          active: '+1 énergie si la couleur en cours est opposée'
        }
      },
      reveal: {
        title: 'Divination',
        text: {
          active: 'La rune donne un petit indice'
        }
      },
      joker: {
        title: 'Rune d\'Odin',
        text: {
          active: 'Peut remplacer n\'importe quelle autre rune'
        }
      },
      hidden: {
        title: 'Rune de Loki',
        text: {
          active: 'Ne révèle jamais sa position'
        }
      }
    },
    results: {
      start: 'Choisis une combinaison de runes pour deviner le secret',
      wrong: 'absente',
      wrongPlural: 'absentes',
      present: 'mal placée',
      presentPlural: 'mal placées',
      found: 'bien placée',
      foundPlural: 'bien placées'
    }
  },
  en: {
    appName: 'ORD',
    baseline: 'Secret of the Futhark',
    ui: {
      dailyGame: 'Game of the day',
      dailyGameSubtitle: 'Only one try',
      trainingGame: 'Free play',
      trainingGameSubtitle: 'Random game',
      endGame: 'End of the game',
      backToMenu: 'Back to menu',
      startAgain: 'Start again',
      startGame: 'Start',
      nextLevel: 'Next level',
      newRune: 'New rune',
      equipment: 'Start item',
      selectEquipment: 'Select an item to help you',
      level: 'Level',
      mode: 'Mode',
      random: 'Free',
      daily: 'Daily',
      play: 'Play',
      name: 'Name',
      date: 'Date',
      seed: 'Seed',
      tries: 'Tries',
      score: 'Score',
      tomorrow: 'See you tomorrow!',
      about: 'About',
      yourName: 'Your name',
      whatsYourName: 'What is your name?',
      whatsYourNameSub: 'Your exploits will not be forgotten',
      scores: 'Scoreboard',
      noScores: 'No scores yet',
      viewScores: 'Best games list'
    },
    gameAbout: {
      title: 'About',
      description: 'An incredible clone of the master mind',
      design: 'Design & Dev',
      other: 'Another game',
      assets: 'Icons',
      contact: 'Contact'
    },
    messages: {
      gameIntro: {
        title: 'Discover the futhark\'s secret',
        description: 'Will you find the lost secret hidden into the ancient alphabet ?'
      }
    },
    effects: {
      shorterSecret: {
        title: 'Shorter secret',
        text: {
          active: 'The secret is one rune shorter (minimum : 2)'
        }
      },
      shorterSecretPlus: {
        title: 'Even shorter secret',
        text: {
          active: 'The secret is two runes shorter (minimum : 2)'
        }
      },
      longerSecret: {
        title: 'Longer secret',
        text: {
          active: 'The secret is one rune longer'
        }
      },
      longerSecretPlus: {
        title: 'Even longer secret',
        text: {
          active: 'The secret is two runes longer'
        }
      },
      increaseVictoryEnergy: {
        title: 'Win + 1 energy',
        text: {
          active: 'Victorious guess give one more energy'
        }
      },
      hideTriesState: {
        title: 'Forgotten colors',
        text: {
          active: 'Colors are not visible on played runes'
        }
      },
      hideTriesRune: {
        title: 'Forgotten runes',
        text: {
          active: 'Symbols are not visible on played runes'
        }
      },
      hidePoolState: {
        title: 'Memory lost',
        text: {
          active: 'No more hints in your rune pool'
        }
      },
      increaseCastCost: {
        title: 'Getting tired',
        text: {
          active: 'Guesses costs one more energy'
        }
      },
      vegvisir: {
        title: 'Reset effects',
        text: {
          active: 'Cancel all the current effects',
          inactive: 'Cancel all the current effects (no effect at the moment)'
        }
      },
      yggdrasil: {
        title: 'Medal of bravery',
        text: {
          active: 'Win 5 energy points',
          inactive: 'Win 5 energy points'
        }
      },
      njord: {
        title: 'Free try',
        text: {
          active: 'Next guess costs no energy',
          inactive: 'Next guess costs no energy'
        }
      },
      skogarmaor: {
        title: 'Shortcut',
        text: {
          active: 'Skip this level and go to the next one',
          inactive: 'Skip this level and go to the next one'
        }
      },
      runeCastCost: {
        title: 'Hostility',
        text: {
          active: 'Costs one more energy if color is opposite'
        }
      },
      reveal: {
        title: 'Divination',
        text: {
          active: 'Rune gives a small hint'
        }
      },
      joker: {
        title: 'Odin\'s rune',
        text: {
          active: 'Can replace any other rune'
        }
      },
      hidden: {
        title: 'Loki\'s Rune',
        text: {
          active: 'Will never reveal its position'
        }
      }
    },
    results: {
      start: 'Pick a combination to guess the secret',
      wrong: 'wrong',
      wrongPlural: 'wrong',
      present: 'misplaced',
      presentPlural: 'misplaced',
      found: 'correct',
      foundPlural: 'correct'
    }
  }
}
