/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { futhark } from '../conf/futhark'

import RuneButtonDummy from './RuneButtonDummy'
/**
  * @desc Score and level block
*/

const Saga = function () {
  const [count, setCount] = useState(0)

  useEffect(() => {
    const nextCount = count >= 6 ? 0 : count + 1
    const interval = setInterval(() => setCount(nextCount), 10000)
    return () => {
      clearInterval(interval)
    }
  }, [count])

  function displaySaga(i) {
    const runes = Object.keys(futhark)
    const oddPosition = i > 1
      ? i > 3 ? 16 : 8
      : i
    const saga = (i % 2 === 0)
      ? [runes[i], runes[i + 8], runes[i + 16], runes[i + 1], runes[i + 9], runes[i + 17]]
      : [runes[oddPosition], runes[oddPosition + 1], runes[oddPosition + 2], runes[oddPosition + 3], runes[oddPosition + 4], runes[oddPosition + 5]]
    return saga.map((rune, index) => (
      <CSSTransition
        key={`transition_${rune}`}
        timeout={index * 200}
        classNames="smallSlide"
      >
        <div className="introDummy" style={{ left: `${index * 50}px`, zIndex: index }}>
          <RuneButtonDummy type={rune} mode="display" />
        </div>
      </CSSTransition>
    ))
  }

  return (
    <TransitionGroup className="dummyWrapper" key="dummyWrapper">
      {displaySaga(count)}
    </TransitionGroup>
  )
}

// Exporting as default
export default Saga
