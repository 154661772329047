/* eslint-disable no-param-reassign */
import React from 'react'

import EnergyDot from '../Ui/EnergyDot'

/**
 * Effects list
 */

function ChangeSecretLength(data, value) {
  data.secretLength += value
  if (data.secretLength <= 2) data.secretLength = 2
  if (data.secretLength > data.runePool.length) data.secretLength = data.runePool.length
  return data
}

function hideTriesRune(data) {
  // This one does nothing to data
  return data
}

function hideTriesState(data) {
  // This one does nothing to data
  return data
}

function hidePoolState(data) {
  // This one does nothing to data
  return data
}

function removeEffects(data) {
  // Add a vegvisir token
  if (!data.vegvisir) data.vegvisir = 1
  else data.vegvisir += 1
  return data
}

function outCast(data) {
  // Add a skogarmaor token
  if (!data.skogarmaor) data.skogarmaor = 1
  else data.skogarmaor += 1
  return data
}

function freeCast(data) {
  // Add a njord token
  if (!data.njord) data.njord = 1
  else data.njord += 1
  return data
}

function yggdrasil(data) {
  // Add a yggdrasil token
  if (!data.yggdrasil) data.yggdrasil = 1
  else data.yggdrasil += 1
  return data
}

function ChangeCastCost(data, value) {
  data.castCost += value
  if (data.castCost === 0) data.castCost = 1
  return data
}

function ChangeEnergy(data, value) {
  data.energy += value
  if (data.energy === 0) data.energy = 1
  return data
}

function ChangeVictoryEnergy(data, value) {
  data.victoryEnergy += value
  if (data.victoryEnergy === 0) data.victoryEnergy = 1
  return data
}

function RuneCastCost(data, value) {
  if (!data.runeCastCost) data.runeCastCost = 0
  data.runeCastCost += value
  return data
}

/**
 * Display rune effects on button
 */
function displayRuneEffect(effect) {
  // Get rune effect
  switch (effect) {
    // Cost
    case 'runeCastCost':
      return <EnergyDot type="cost" />
    case 'bonus':
      return <EnergyDot type="energyBonus" />
    default:
      break
  }
}

/**
 *  Compute rune effect, can be once on draw or on each cast
*/
function computeEffect(data, effect, mode) {
  // Change shorterSecret if secret already too short
  if (effect === 'shorterSecret' && data.secretLength === 2) effect = 'longerSecret'

  // Get rune effect
  switch (effect) {
    // ADD
    case 'longerSecretPlus':
      data = ChangeSecretLength(data, 2)
      break
    case 'longerSecret':
      data = ChangeSecretLength(data, 1)
      break
    case 'shorterSecretPlus':
      data = ChangeSecretLength(data, -1)
      break
    case 'shorterSecret':
      data = ChangeSecretLength(data, -1)
      break
    case 'hideTriesRune':
      data = hideTriesRune(data)
      break
    case 'hideTriesState':
      data = hideTriesState(data)
      break
    case 'hidePoolState':
      data = hidePoolState(data)
      break
    case 'increaseVictoryEnergy':
      data = ChangeVictoryEnergy(data, 1)
      break
    case 'increaseCastCost':
      data = ChangeCastCost(data, 1)
      break
    case 'removeEffects':
      data = removeEffects(data)
      break
    case 'outCast':
      data = outCast(data)
      break
    case 'freeCast':
      data = freeCast(data)
      break
    case 'yggdrasil':
      data = yggdrasil(data)
      break
    // CAST
    case 'runeCastCost':
      data = RuneCastCost(data, 1)
      break
    default:
      break
  }

  // Add effect for display
  if (mode === 'add'
    && effect !== 'removeEffects'
    && effect !== 'outCast'
    && effect !== 'freeCast'
    && effect !== 'yggdrasil'
    && effect !== 'longerSecret'
    && effect !== 'longerSecretPlus'
    && effect !== 'shorterSecret'
    && effect !== 'shorterSecretPlus'
  ) {
    if (!data.addEffect) data.addEffect = []
    data.addEffect.push(effect)
  }

  return data
}

function addFx(data, effects, mode) {
  if (!effects || effects.length === 0) {
    if (data.addEffect) delete data.addEffect
    return data
  }
  for (let index = 0; index < effects.length; index += 1) {
    data = computeEffect(data, effects[index], mode)
  }
  return data
}

export {
  addFx,
  displayRuneEffect,
  computeEffect,
  RuneCastCost,
  ChangeSecretLength,
  ChangeCastCost,
  ChangeEnergy,
  ChangeVictoryEnergy
}
