/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { connect } from 'react-redux'
import useSound from 'use-sound'

import {
  playEffect, settings
} from '../redux/actions/index'
import { wording } from '../wording/wording'

import tokenVegvisir from '../pictures/tokenVegvisir.png'
import tokenKnot from '../pictures/tokenKnot.png'
import tokenYggdrasil from '../pictures/tokenYggdrasil.png'
import tokenNjord from '../pictures/tokenNjord.png'

import tokenKnotSFX1 from '../sound/tokenKnot.mp3'
import tokenNjordSFX1 from '../sound/tokenNjord.mp3'
import tokenVegvisirSFX1 from '../sound/tokenVegvisir.mp3'
import tokenYggdrasilSFX1 from '../sound/tokenYggdrasil.mp3'

import '../css/token.scss'

/**
  * @desc Action token
*/

const mapStateToProps = (state) => ({
  addEffect: state.addEffect,
  language: state.settings.language,
  state: state.state,
  volume: state.settings.volume
})

function mapDispatchToProps(dispatch) {
  return {
    playEffect: (payload) => dispatch(playEffect(payload)),
    settings: (payload) => dispatch(settings(payload))
  }
}

function Token({
  type, addEffect, qty, language, settings, state, playEffect, volume
}) {
  const [tokenKnotSFX] = useSound(tokenKnotSFX1, { volume })
  const [tokenNjordSFX] = useSound(tokenNjordSFX1, { volume })
  const [tokenVegvisirSFX] = useSound(tokenVegvisirSFX1, { volume })
  const [tokenYggdrasilSFX] = useSound(tokenYggdrasilSFX1, { volume })

  function getToken(runeType) {
    switch (runeType) {
      case 'vegvisir':
        return (
          <img
            src={tokenVegvisir}
            alt="Vegvisir"
            className={addEffect?.length > 0 ? 'ready' : 'disabled'}
            onMouseDown={tokenVegvisirSFX}
            onClick={
              addEffect?.length > 0
                ? () => playEffect({ mode: 'play', effect: 'removeEffects' })
                : undefined
            }
          />
        )

      case 'njord':
        return (
          <img
            src={tokenNjord}
            alt=""
            className={state === 'battle' ? 'ready' : 'disabled'}
            onMouseDown={tokenNjordSFX}
            onClick={
              state === 'battle'
                ? () => playEffect({ mode: 'play', effect: 'freeCast' })
                : undefined
            }
          />
        )

      case 'yggdrasil':
        return (
          <img
            src={tokenYggdrasil}
            alt=""
            className="ready"
            onMouseDown={tokenYggdrasilSFX}
            onClick={() => playEffect({ mode: 'play', effect: 'yggdrasil' })}
          />
        )

      case 'skogarmaor':
        return (
          <img
            src={tokenKnot}
            alt=""
            className={state === 'battle' ? 'ready' : 'disabled'}
            onMouseDown={tokenKnotSFX}
            onClick={() => playEffect({ mode: 'play', effect: 'outCast' })}
          />
        )

      default:
        break
    }
  }

  // Display component

  return (
    <div className="token">
      {qty > 1 && <span className="qty">{qty}</span>}
      {getToken(type)}

      <div className="description">
        {wording[language].effects[type].text.active}
      </div>
    </div>
  )
}

// Exporting as default
export default connect(mapStateToProps, mapDispatchToProps)(Token)
