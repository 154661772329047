/* eslint-disable no-param-reassign */

/**
  * @desc Display flash animation
*/

const flash = (data, flashValue) => {
  const { } = data

  if (flashValue) {
    data.flash = flashValue
  } else if (data.flash) delete data.flash

  return data
}

export { flash }
