import { computeCastCost } from '../../utils/computeCastCost'

/**
  * @desc Computing the play of a single rune
*/

const playRune = (data, rune) => {
  const { aett } = data

  // Add rune to current try
  if (!data.currentTry) data.currentTry = []
  data.currentTry.push(rune)

  // Compute cast cost
  data = computeCastCost(data, rune, aett, 'add')

  return data
}

export { playRune }
