/* eslint-disable no-param-reassign */
import { score } from '../../utils/score'

/**
  * @desc Computing the play of a single rune
*/

const playEffect = (data, effect) => {
  const { } = data

  switch (effect) {
    case 'removeEffects':
      // Remove all currently active effect
      if (data.addEffect) delete data.addEffect
      if (data.vegvisir) data.vegvisir -= 1
      data.castCost = 1
      break

    case 'freeCast':
      // Next cast costs 0
      if (data.njord) data.njord -= 1
      // Set next cast to free
      data.freeCast = true
      break

    case 'yggdrasil':
      // Gives 5 energy
      if (data.yggdrasil) data.yggdrasil -= 1
      data.energy += 5
      break

    case 'outCast':
      // Store outcast
      const outCast = data.newRune
      // Send rune back to the end of the saga & remove from pool
      data.runeSaga.splice(data.runeSaga.indexOf(outCast), 1)
      data.runeSaga.push(outCast)
      data.runePool.splice(data.runePool.indexOf(outCast), 1)
      // Remove one token
      if (data.skogarmaor) data.skogarmaor -= 1
      // Set secret as found
      data.runeSecretFound = true
      break

    default:
      break
  }

  // Add score
  data = score(data, 'token')

  // Reset hover message
  if (data.message) delete data.message

  return data
}

export { playEffect }
