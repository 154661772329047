/* eslint-disable no-param-reassign */

/**
  * @desc Display tooltip
*/

const message = (data, message) => {
  const { } = data

  if (message) {
    data.message = message
  } else if (data.message) delete data.message

  return data
}

export { message }
