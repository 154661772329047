function score(data, event) {
  switch (event) {
    case 'secretFound':
      data.score += 100
      break
    case 'nextRound':
      data.score += 5
      break
    case 'colorCombo':
      data.score += 30
      break
    case 'token':
      data.score += 20
      break
    case 'runeFound':
      data.score += 5
      break
    case 'runePresent':
      data.score += 3
      break
    default:
      break
  }
  return data
}

export {
  score
}
