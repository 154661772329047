/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/button-has-type */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import useSound from 'use-sound'
import { format } from 'date-fns'

import { setGameState } from '../redux/actions/index'
import { clog } from '../utils/utils'
import { wording } from '../wording/wording'

import Title from '../Ui/Title'
import Separator from '../Ui/Separator'

import '../css/scores.scss'

import sigilTyr from '../pictures/sigilTyr.png'
import gameRanked from '../pictures/gameRanked.png'
import gameUnranked from '../pictures/gameUnranked.png'

import menuSFX1 from '../sound/menu.mp3'
import menuHoverSFX1 from '../sound/menuHover.mp3'

const mapStateToProps = (state) => ({
  language: state.settings.language,
  level: state.level,
  stats: state.stats,
  volume: state.settings.volume
})

function mapDispatchToProps(dispatch) {
  return {
    setGameState: (payload) => dispatch(setGameState(payload))
  }
}

const About = function ({
  setGameState, language, level, stats, volume
}) {
  clog('Score view', 'location')

  const [scores, setScores] = useState('')
  const [mode, setMode] = useState('daily')

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://mhd20.com/runes/getscore/')
        const json = await response.json()
        setScores(json)
      } catch (error) {
        console.log('error', error)
      }
    }

    fetchData()
  }, [])

  const [menuSFX] = useSound(menuSFX1, { volume })
  const [menuHoverSFX] = useSound(menuHoverSFX1, { volume })

  function displayScores(scores, mode) {
    if (!scores[mode]) {
      return (
        <div className="noScore runeDemo">
          {wording[language].ui.noScores}
        </div>
      )
    }

    // Random mode : simple list
    if (mode === 'random') {
      return (
        <>
          <div className="scoreLine header">
            <div className="scoreName">{wording[language].ui.name}</div>
            <div className="scoreScore">{wording[language].ui.score}</div>
            <div className="scoreLevel">{wording[language].ui.level}</div>
            <div className="scoreSeed">{wording[language].ui.seed}</div>
            <div className="scoreDate">{wording[language].ui.date}</div>
          </div>
          {scores[mode].map((score, index) => (
            <div key={`scoreLine_${index}`} className="scoreLine">
              <div className="scoreName">{score.name}</div>
              <div className="scoreScore">{score.score}</div>
              <div className="scoreLevel">{score.level}</div>
              <div className="scoreSeed">{score.seed}</div>
              <div className="scoreDate">{format(score.date, 'd MMM yy - H:mm')}</div>
            </div>
          ))}
        </>
      )
    }

    // Daily mode : group by day
    let currentDate
    return scores[mode].map((score, index) => {
      let separator = false
      if (score.seed !== currentDate) {
        currentDate = score.seed
        separator = (
          <>
            <div className="scoreDailyBar">
              <div className="winner">
                <div className="winnerName">{score.name}</div>
                <div className="winnerScore">{score.score}</div>
              </div>
              <div className="day">{score.seed}</div>
            </div>
            <div className="scoreLine header">
              <div className="scoreName">{wording[language].ui.name}</div>
              <div className="scoreScore">{wording[language].ui.score}</div>
              <div className="scoreLevel">{wording[language].ui.level}</div>
              {/* <div className="scoreSeed">{wording[language].ui.seed}</div> */}
              <div className="scoreDate long">{wording[language].ui.date}</div>
            </div>
          </>
        )
      }
      return (
        <>
          {separator && <div className="center"><Separator /></div>}
          {separator}
          <div key={`scoreLine_${index}`} className="scoreLine">
            <div className="scoreName">{score.name}</div>
            <div className="scoreScore">{score.score}</div>
            <div className="scoreLevel">{score.level}</div>
            {/* <div className="scoreSeed">{score.seed}</div> */}
            <div className="scoreDate long">{format(score.date, 'd MMM yy - H:mm')}</div>
          </div>
        </>
      )
    })
  }

  return (
    <div className="mainWrapper">
      <div className="appWrapper">
        <Title
          title={wording[language].ui.scores}
          subtitle={wording[language].ui.viewScores}
        />
        <div className="scoreSelector">
          <button
            onMouseDown={menuSFX}
            onMouseEnter={() => menuHoverSFX()}
            className={`actionButton small ${mode === 'daily' ? 'selected' : 'notSelected'}`}
            onClick={() => setMode('daily')}
          >
            <img
              src={gameRanked}
              alt="Ranked Game"
            />
            <div className="description">
              <div className="title">
                {wording[language].ui.dailyGame}
              </div>
            </div>
          </button>
          <button
            onMouseDown={menuSFX}
            onMouseEnter={() => menuHoverSFX()}
            className={`actionButton small ${mode === 'random' ? 'selected' : 'notSelected'}`}
            onClick={() => setMode('random')}
          >
            <img
              src={gameUnranked}
              alt="Free game"
            />
            <div className="description">
              <div className="title">
                {wording[language].ui.trainingGame}
              </div>
            </div>
          </button>
        </div>
        <div className={`scoresWrapper ${scores?.random?.length > 11 ? 'long' : 'short'}`}>
          <div className="scoresList">
            {scores ? displayScores(scores, mode) : <div>Loading</div>}
            <div className="center"><Separator /></div>
          </div>
        </div>
        <button
          className="actionButton"
          onMouseDown={menuSFX}
          onMouseEnter={() => menuHoverSFX()}
          onClick={() => setGameState({ state: 'reset' })}
        >
          <img
            src={sigilTyr}
            className="big"
            alt="Ranked Game"
          />
          <div className="description">
            <div className="title">
              {wording[language].ui.play}
            </div>
            <div className="subtitle">
              {wording[language].ui.backToMenu}
            </div>
          </div>
        </button>
      </div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(About)
