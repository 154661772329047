/* eslint-disable no-param-reassign */
import { computeCastCost } from '../../utils/computeCastCost'

/**
  * @desc Delete a played rune
*/

const deleteRune = (data, rune) => {
  const { currentTry, aett } = data

  // Remove rune from current try
  data.currentTry = currentTry.filter((item) => item !== rune)

  // Compute cast cost
  data = computeCastCost(data, rune, aett, 'delete')

  return data
}

export { deleteRune }
