/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { connect } from 'react-redux'

import useSound from 'use-sound'
import { setGameState, settings } from '../redux/actions/index'

import '../css/volume.scss'

import volumeLoud from '../pictures/volumeLoud.png'
import volumeSilent from '../pictures/volumeSilent.png'
import volumeOff from '../pictures/volumeOff.png'

import menuSFX1 from '../sound/menu.mp3'

/**
  * @desc Message display
*/

const mapStateToProps = (state) => ({
  volume: state.settings.volume
})

function mapDispatchToProps(dispatch) {
  return {
    setGameState: (payload) => dispatch(setGameState(payload)),
    settings: (payload) => dispatch(settings(payload))
  }
}

const Volume = function ({
  volume, settings
}) {
  const [menuSFX] = useSound(menuSFX1, { volume })

  function castVolume() {
    let expectedVolume
    if (volume === 0.8) expectedVolume = 0.3
    else if (volume === 0.3) expectedVolume = 0
    else expectedVolume = 0.8

    settings({
      setting: 'volume',
      volume: expectedVolume
    })
  }

  function getVolumePicture(currentVolume) {
    let picture
    if (currentVolume === 0.8) picture = volumeLoud
    else if (currentVolume === 0.3) picture = volumeSilent
    else picture = volumeOff
    return picture
  }

  return (
    <div
      className="volumeControl"
      onMouseDown={menuSFX}
      onClick={() => castVolume()}
    >
      <img src={getVolumePicture(volume)} alt="Volume control" />
    </div>
  )
}

// Exporting as default
export default connect(mapStateToProps, mapDispatchToProps)(Volume)
