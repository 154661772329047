/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { connect } from 'react-redux'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import EnergyDot from './EnergyDot'
import Explanations from './Explanations'
import Token from './Token'

import { gameSettings } from '../conf/settings'
import { playEffect, settings } from '../redux/actions/index'

import '../css/gameBar.scss'

/**
  * @desc New rune display
*/

const mapStateToProps = (state) => ({
  triesCount: state.triesCount,
  secret: state.secret,
  currentTry: state.currentTry,
  castCost: state.castCost,
  runeCastCost: state.runeCastCost,
  playedCombos: state.playedCombos,
  aett: state.aett,
  energy: state.energy,
  victoryEnergy: state.victoryEnergy,
  runeSecretFound: state.runeSecretFound,
  energyVariation: state.energyVariation,
  vegvisir: state.vegvisir,
  skogarmaor: state.skogarmaor,
  yggdrasil: state.yggdrasil,
  njord: state.njord,
  addEffect: state.addEffect,
  state: state.state,
  language: state.settings.language
})

function mapDispatchToProps(dispatch) {
  return {
    playEffect: (payload) => dispatch(playEffect(payload)),
    settings: (payload) => dispatch(settings(payload))
  }
}

function ActionTokens({
  vegvisir, skogarmaor, njord, yggdrasil, state
}) {
  const tokens = []
  if (vegvisir > 0) {
    tokens.push(
      <CSSTransition
        key="token_vegvisir"
        timeout={800}
        classNames="tokenActivation"
        appear
      >
        <Token type="vegvisir" qty={vegvisir} />
      </CSSTransition>
    )
  }
  if (njord > 0) {
    tokens.push(
      <CSSTransition
        key="token_njord"
        timeout={800}
        classNames="tokenActivation"
        appear
      >
        <Token type="njord" qty={njord} />
      </CSSTransition>
    )
  }
  if (yggdrasil > 0) {
    tokens.push(
      <CSSTransition
        key="token_yggdrasil"
        timeout={800}
        classNames="tokenActivation"
        appear
      >
        <Token type="yggdrasil" qty={yggdrasil} />
      </CSSTransition>
    )
  }
  if (skogarmaor > 0) {
    tokens.push(
      <CSSTransition
        key="token_skogarmaor"
        timeout={800}
        classNames="tokenActivation"
        appear
      >
        <Token type="skogarmaor" qty={skogarmaor} />
      </CSSTransition>
    )
  }

  // Display component
  if (state === 'gameOver' || state === 'welcome') return null
  return (
    <div key="actionBar" className="actionBar">
      <TransitionGroup className="actionsCount">
        {tokens}
      </TransitionGroup>
    </div>
  )
}

// Exporting as default
export default connect(mapStateToProps, mapDispatchToProps)(ActionTokens)
