/* eslint-disable react/no-unknown-property */
/* eslint-disable react/prop-types */
import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { setGameState, settings } from '../redux/actions/index'

import freyrCharacter from '../pictures/character_freyr.png'
import tyrCharacter from '../pictures/character_tyr.png'
import heimdallCharacter from '../pictures/character_heimdall.png'
import heimdallInvertCharacter from '../pictures/character_heimdall_invert.png'
import odinCharacter from '../pictures/character_odin.png'
import lokiCharacter from '../pictures/character_loki.png'

import '../css/characters.scss'

/**
  * @desc Characters
*/

const mapStateToProps = (state) => ({
  state: state.state,
  aett: state.aett,
  flash: state.flash

})

function mapDispatchToProps(dispatch) {
  return {
    setGameState: (payload) => dispatch(setGameState(payload)),
    settings: (payload) => dispatch(settings(payload))
  }
}

const Characters = function ({
  left, right, state, small, dark
}) {
  const characterWrapperClasses = classNames({
    characterWrapper: true,
    small,
    dark
  })

  const leftEntrance = small ? 'leftEntranceSlow' : 'leftEntrance'
  const rightEntrance = small ? 'rightEntranceSlow' : 'rightEntrance'
  const timeout = small ? 1200 : 600

  return (
    <TransitionGroup className={characterWrapperClasses}>
      {left && left === 'tyr' && (
        <CSSTransition key="leftTyr" timeout={timeout} classNames={leftEntrance} appear enter>
          <img className="left" src={tyrCharacter} alt="Tyr" />
        </CSSTransition>
      )}
      {left && left === 'freyr' && (
        <CSSTransition key="leftFreyr" timeout={timeout} classNames={leftEntrance} appear enter>
          <img className="left" src={freyrCharacter} alt="Rreyr" />
        </CSSTransition>
      )}
      {left && left === 'heimdall' && (
        <CSSTransition key="leftHeimdall" timeout={timeout} classNames={leftEntrance} appear enter>
          <img className="left" src={heimdallCharacter} alt="Heimdall" />
        </CSSTransition>
      )}
      {left && left === 'odin' && (
        <CSSTransition key="leftOdin" timeout={timeout} classNames={leftEntrance} appear enter>
          <img className="left" src={odinCharacter} alt="Odin" />
        </CSSTransition>
      )}
      {left && left === 'loki' && (
        <CSSTransition key="leftLoki" timeout={timeout} classNames={leftEntrance} appear enter>
          <img className="left" src={lokiCharacter} alt="Loki" />
        </CSSTransition>
      )}

      {right && right === 'tyr' && (
        <CSSTransition key="rightTyr" timeout={timeout} classNames={rightEntrance} appear enter>
          <img className="right" src={tyrCharacter} alt="Tyr" />
        </CSSTransition>
      )}
      {right && right === 'freyr' && (
        <CSSTransition key="rightFreyr" timeout={timeout} classNames={rightEntrance} appear enter>
          <img className="right" src={freyrCharacter} alt="Freyr" />
        </CSSTransition>
      )}
      {right && right === 'heimdall' && (
        <CSSTransition key="rightHeimdall" timeout={timeout} classNames={rightEntrance} appear enter>
          <img className="right" src={heimdallInvertCharacter} alt="Heimdall" />
        </CSSTransition>
      )}
      {right && right === 'odin' && (
        <CSSTransition key="rightOdin" timeout={timeout} classNames={rightEntrance} appear enter>
          <img className="right" src={odinCharacter} alt="Odin" />
        </CSSTransition>
      )}
      {right && right === 'loki' && (
        <CSSTransition key="rightLoki" timeout={timeout} classNames={rightEntrance} appear enter>
          <img className="right" src={lokiCharacter} alt="Loki" />
        </CSSTransition>
      )}
    </TransitionGroup>
  )
}

// Exporting as default
export default connect(mapStateToProps, mapDispatchToProps)(Characters)
