// Main game settings
const gameSettings = {
  appName: 'ORD',
  castCost: 1,
  victoryEnergy: 1,
  victoryEnergyBonus: 3,
  secretLength: 3,
  poolLength: 3,
  energy: 4,
  minimumCombo: 3,
  volume: 0.8,
  decreaseRythm: 2000,
  hiddenAddEffects: ['longerSecretPlus', 'longerSecret', 'shorterSecret', 'shorterSecretPlus']
}

// Initial game state
const initialState = {
  state: 'welcome',
  settings: {
    language: 'fr',
    volume: gameSettings.volume
  }
}

export {
  initialState,
  gameSettings
}
