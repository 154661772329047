/* eslint-disable no-param-reassign */
/**
  * @desc Resetting game to title screen
*/

import { getSeed } from '../../utils/seed'

const gameIntro = (data, mode, bonus) => {
  // Set game state
  data.state = 'gameIntro'
  data.mode = mode
  data.seed = getSeed(mode)
  // Aett preview
  if (bonus) data.aett = bonus
  else delete data.aett
  // if it's a daily game, store the day in the settings
  if (mode !== 'random') data.settings.daily = data.seed
  // Visual effect
  data.flash = 1
  return data
}

export { gameIntro }
