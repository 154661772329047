/* eslint-disable no-param-reassign */
import { score } from '../../utils/score'

const updateScore = (data, mode) => {
  const { } = data

  switch (mode) {
    case 'decrease':
      if (data.score > 0) data.score -= 1
      break

    default:
      break
  }

  return data
}

export { updateScore }
