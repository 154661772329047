import React from 'react'

import '../css/rune.scss'

/**
  * @desc Wrapper for all the possible action
*/

function RuneSocket({
  children
}) {
  // Display component
  return (
    <div className="runeSocket">
      {children}
    </div>
  )
}

// Exporting as default
export default RuneSocket
