/* eslint-disable react/prop-types */
/* eslint-disable react/button-has-type */
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import useSound from 'use-sound'

import { setGameState, settings } from '../redux/actions/index'
import { clog } from '../utils/utils'
import { wording } from '../wording/wording'

import Separator from '../Ui/Separator'

import '../css/app.scss'
import '../css/gameOver.scss'

import menu from '../pictures/menu.png'

import menuSFX1 from '../sound/menu.mp3'
import menuHoverSFX1 from '../sound/menuHover.mp3'

const mapStateToProps = (state) => ({
  language: state.settings.language,
  level: state.level,
  stats: state.stats,
  volume: state.settings.volume,
  name: state.settings.name,
  submitScore: state.submitScore
})

function mapDispatchToProps(dispatch) {
  return {
    setGameState: (payload) => dispatch(setGameState(payload)),
    settings: (payload) => dispatch(settings(payload))
  }
}

const GameOver = function ({
  setGameState, settings, language, stats, volume, name, submitScore
}) {
  clog('GameOver', 'location')

  const [lastGame] = stats.games.slice(-1)

  async function saveScore() {
    let response
    if (!submitScore) {
      response = await fetch('https://mhd20.com/runes/postscore/', {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain'
        },
        body: JSON.stringify({
          name,
          level: lastGame.level,
          score: lastGame.score,
          mode: lastGame.mode,
          seed: lastGame.seed,
          tries: lastGame.tries
        })
      })
        .then(
          settings({
            setting: 'submitScore'
          })
        )
    }
    return response
  }

  useEffect(() => {
    // alert('Disabled scores !')
    saveScore()
  }, [])

  const [menuSFX] = useSound(menuSFX1, { volume })
  const [menuHoverSFX] = useSound(menuHoverSFX1, { volume })

  return (
    <div className="mainWrapper">
      <div className="appWrapper welcome">
        <div className="report">
          <h1>{wording[language].ui.endGame}</h1>
          <div className="stats">
            <div>
              <span className="title">{wording[language].ui.yourName}</span>
              {name}
            </div>
            <div>
              <span className="title">{wording[language].ui.level}</span>
              {lastGame.level}
            </div>
            <div>
              <span className="title">{wording[language].ui.score}</span>
              {lastGame.score}
            </div>
            <div>
              <span className="title">{wording[language].ui.mode}</span>
              {wording[language].ui[lastGame.mode]}
            </div>
            <div>
              <span className="title">{wording[language].ui.seed}</span>
              {lastGame.seed}
            </div>
            <div>
              <span className="title">{wording[language].ui.tries}</span>
              {lastGame.tries}
            </div>
          </div>
        </div>
        <Separator />
        <button
          className="actionButton"
          onMouseDown={menuSFX}
          onMouseEnter={() => menuHoverSFX()}
          onClick={() => setGameState({ state: 'reset' })}
        >
          <img
            src={menu}
            alt="Start again"
          />
          <div className="description">
            <div className="title">
              {wording[language].ui.startAgain}
            </div>
            <div className="subtitle">
              {wording[language].ui.backToMenu}
            </div>
          </div>
        </button>
        <button
          className="secondaryButton"
          onMouseDown={menuSFX}
          onMouseEnter={() => menuHoverSFX()}
          onClick={() => setGameState({ state: 'gameScores' })}
        >
          {wording[language].ui.scores}
        </button>
      </div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(GameOver)
