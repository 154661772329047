/* eslint-disable no-param-reassign */
import { addRune } from '../../utils/runes'
import { futhark } from '../../conf/futhark'

/**
  * @desc Round won
*/

const victory = (data) => {
  // Add a rune
  data.newRune = addRune(data.runePool, data.runeSaga)
  // data.newRune = 'berkana'
  // Color background to current aett
  data.aett = futhark[data.newRune].aett
  // Add the new tune to pool
  data.runePool.push(data.newRune)
  // Visual effect
  data.flash = 1
  // Set game state
  data.state = 'victory'

  return data
}

export { victory }
