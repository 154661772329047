/* eslint-disable react/no-unknown-property */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { connect } from 'react-redux'

import { setGameState, settings } from '../redux/actions/index'

import '../css/storm.scss'

/**
  * @desc Animated rune cloud
*/

const mapStateToProps = (state) => ({
  state: state.state,
  flash: state.flash
})

function mapDispatchToProps(dispatch) {
  return {
    setGameState: (payload) => dispatch(setGameState(payload)),
    settings: (payload) => dispatch(settings(payload))
  }
}

const Storm = function ({
  flash, settings
}) {
  function castFlash(flashValue) {
    settings({
      setting: 'flash',
      flash: flashValue
    })
  }

  return (
    <div
      className="storm"
      flash={flash}
      onAnimationEnd={() => castFlash(0)}
    />
  )
}

// Exporting as default
export default connect(mapStateToProps, mapDispatchToProps)(Storm)
