/* eslint-disable no-param-reassign */
/**
  * @desc Display score page
*/

const gameScores = (data) => {
  // Set game state
  data.state = 'gameScores'
  // Visual effect
  data.flash = 1
  return data
}

export { gameScores }
