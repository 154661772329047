import { createStore } from 'redux'
import { composeWithDevToolsDevelopmentOnly } from '@redux-devtools/extension'
import rootReducer from '../reducers/index'
import { loadState } from '../localStorage/index'

const persistedState = loadState()

const composeEnhancers = composeWithDevToolsDevelopmentOnly({})

const store = createStore(
  rootReducer,
  persistedState,
  composeEnhancers()
)

export default store
