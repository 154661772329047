/* eslint-disable no-param-reassign */
/**
  * @desc Resetting game to title screen
*/

const gameAbout = (data) => {
  // Set game state
  data.state = 'gameAbout'
  // Visual effect
  data.flash = 1
  return data
}

export { gameAbout }
