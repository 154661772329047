/* eslint-disable react/prop-types */
import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { futhark } from '../conf/futhark'
import { wording } from '../wording/wording'
import { gameSettings } from '../conf/settings'
import { updateScore } from '../redux/actions/index'

import '../css/score.scss'

/**
  * @desc Score and level block
*/

const mapStateToProps = (state) => ({
  score: state.score,
  newRune: state.newRune,
  level: state.level,
  language: state.settings.language,
  runeSecretFound: state.runeSecretFound
})

function mapDispatchToProps(dispatch) {
  return {
    updateScore: (payload) => dispatch(updateScore(payload))
  }
}

const Score = function ({
  score, level, newRune, language, runeSecretFound, updateScore
}) {
  function scoreTimeout() {
    updateScore({ mode: 'updateScore', effect: 'decrease' })
  }

  useEffect(() => {
    const timer = setInterval(scoreTimeout, gameSettings.decreaseRythm)
    return () => clearInterval(timer)
  }, [scoreTimeout])

  if (!runeSecretFound) {
    return (
      <div className="score">
        <div className="currentRune">
          <div id="currentRuneFuthark">{futhark[newRune]?.letter}</div>
        </div>
        <div className="currentRuneName">{newRune}</div>
        <div className="currentLevel">
          {`${wording[language].ui.level} ${level}`}
        </div>
        <div className="currentScore">
          <div className="title">{`${wording[language].ui.score}`}</div>
          {score}
        </div>
      </div>
    )
  }
  return null
}

// Exporting as default
export default connect(mapStateToProps, mapDispatchToProps)(Score)
