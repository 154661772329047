/* Define array of consonants and vowels from English alphabet */
const consonants = ['F', 'þ', 'R', 'K', 'G', 'W', 'H', 'N', 'J', 'P', 'Z', 'S', 'T', 'B', 'M', 'L', 'D']
const consCount = consonants.length
const vowels = ['U', 'A', 'I', 'E', 'O']
const vowCount = vowels.length

let randomConsId
let randomVowsId

function randomWholeNumCons() {
  randomConsId = Math.floor(Math.random() * (consCount))
}

function randomWholeNumVows() {
  randomVowsId = Math.floor(Math.random() * (vowCount))
}

/* Generate the string */
function generateString(stringLength = 8) {
  let i
  let myString

  /* Here happens the actual creation of the string */
  myString = ''
  for (i = 0; i <= stringLength - 1; i++) {
    /* Get the random letters, one consonant and one vowel */
    randomWholeNumCons()
    randomWholeNumVows()
    if (i % 2 === 0) {
      myString += consonants[randomConsId]
    } else {
      myString += vowels[randomVowsId]
    }
  }

  return myString
}

function getSeed(mode) {
  if (mode === 'random') {
    return generateString()
  }

  const dt = new Date()
  return `${dt.getDate()}/${dt.getMonth() + 1}/${dt.getFullYear()}`
}

export {
  getSeed
}
