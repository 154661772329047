/* eslint-disable no-param-reassign */
import React from 'react'

import { futhark } from '../conf/futhark'
import { RuneCastCost } from './effects'
import { hostileFamily } from './hostileFamily'

/**
 * Effects list
 */

function computeCastCost(data, rune, aett, mode) {
  if (futhark[rune].castEffect && hostileFamily(futhark[rune].aett, aett)) {
    for (let index = 0; index < futhark[rune].castEffect.length; index += 1) {
      const effect = futhark[rune].castEffect[index]

      switch (effect) {
        // Cost
        case 'runeCastCost':
          data = RuneCastCost(data, mode === 'add' ? 1 : -1)
          break
        default:
          break
      }
    }
  }

  return data
}

export {
  computeCastCost
}
