/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
import React from 'react'
import { connect } from 'react-redux'
import useSound from 'use-sound'
import classNames from 'classnames'

import { setGameState, settings } from '../redux/actions/index'
import { clog } from '../utils/utils'
import { wording } from '../wording/wording'
import { getSeed } from '../utils/seed'
import { gameSettings } from '../conf/settings'

import '../css/app.scss'
import '../css/welcome.scss'
import '../css/button.scss'

import Saga from '../Ui/DisplaySaga'

import logo from '../pictures/logo.png'
import gameRanked from '../pictures/gameRanked.png'
import gameUnranked from '../pictures/gameUnranked.png'

import menuSFX1 from '../sound/menu.mp3'
import menuHoverSFX1 from '../sound/menuHover.mp3'

const mapStateToProps = (state) => ({
  state: state.state,
  language: state.settings.language,
  daily: state.settings.daily,
  aett: state.aett,
  volume: state.settings.volume
})

function mapDispatchToProps(dispatch) {
  return {
    setGameState: (payload) => dispatch(setGameState(payload)),
    settings: (payload) => dispatch(settings(payload))
  }
}

const Welcome = function ({
  setGameState, settings, state, language, aett, daily, volume
}) {
  clog('Welcome', 'location')

  const [menuSFX] = useSound(menuSFX1, { volume })
  const [menuHoverSFX] = useSound(menuHoverSFX1, { volume })

  function hoverButton(aett, mode) {
    if (aett) menuHoverSFX()
    setGameState({ state: 'welcome', bonus: aett })
  }

  function startGame(mode) {
    setGameState({ state: 'gameIntro', mode })
    settings({
      setting: 'message',
      message: null
    })
  }

  const appWrapperClasses = classNames({
    'appWrapper welcome': true,
    [`${aett}`]: aett
  })

  const dailyButtonClasses = classNames({
    actionButton: true,
    disabled: daily === getSeed()
  })

  return (
    <div className="mainWrapper runeDemo">
      <div className={appWrapperClasses}>
        <div className="appInfos">
          <div className="localeSelector">
            <span className={language === 'fr' ? 'active' : 'inactive'} onClick={() => settings({ setting: 'preference', type: 'language', value: 'fr' })}>FR</span>
            <span className={language === 'en' ? 'active' : 'inactive'} onClick={() => settings({ setting: 'preference', type: 'language', value: 'en' })}>EN</span>
          </div>
          <span
            onMouseDown={menuSFX}
            onMouseEnter={() => menuHoverSFX()}
            onClick={() => setGameState({ state: 'gameAbout' })}
            style={{ cursor: 'pointer' }}
          >
            {wording[language].ui.about}
          </span>
        </div>

        <img
          className="logo"
          src={logo}
          alt={`${wording[language].appName} ${wording[language].baseline}`}
        />
        <div className="baseline">{wording[language].baseline}</div>

        <Saga />

        <button
          className={dailyButtonClasses}
          onMouseEnter={(state === 'welcome' && daily !== getSeed()) ? (() => hoverButton('tyr', 'daily')) : undefined}
          onMouseLeave={state === 'welcome' ? (() => hoverButton(null)) : undefined}
          onMouseDown={menuSFX}
          onClick={(state === 'welcome' && daily !== getSeed()) ? (() => startGame('daily')) : undefined}
        >
          <img
            src={gameRanked}
            className="big"
            alt="Ranked Game"
          />
          <div className="description">
            <div className="title">
              {wording[language].ui.dailyGame}

            </div>
            <div className="subtitle">
              {daily !== getSeed()
                ? `${wording[language].ui.dailyGameSubtitle} : ${getSeed()}`
                : wording[language].ui.tomorrow}
            </div>
          </div>
        </button>
        <button
          className="actionButton training"
          onMouseEnter={state === 'welcome' ? (() => hoverButton('freyr', 'random')) : undefined}
          onMouseLeave={state === 'welcome' ? (() => hoverButton(null)) : undefined}
          onMouseDown={menuSFX}
          onClick={state === 'welcome' ? (() => startGame('random')) : undefined}
        >
          <img
            src={gameUnranked}
            className="big"
            alt="Free Game"
          />
          <div className="description">
            <div className="title">
              {wording[language].ui.trainingGame}
            </div>
            <div className="subtitle">
              {wording[language].ui.trainingGameSubtitle}
            </div>
          </div>
        </button>
        <button
          className="secondaryButton"
          onMouseDown={menuSFX}
          onMouseEnter={() => menuHoverSFX()}
          onClick={() => setGameState({ state: 'gameScores' })}
        >
          {wording[language].ui.scores}
        </button>
      </div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Welcome)
