/**
  * @desc Reset everything
*/

const reset = (data) => {
  const keep = ['state', 'previousState', 'stats', 'settings']
  // Reset game data
  const keys = Object.keys(data)
  keys.forEach((key) => {
    if (!keep.includes(key)) delete data[key]
  })
  // Set game state
  data.state = 'welcome'

  return data
}

export { reset }
