/**
 * Compute aett compatibility
 */

function hostileFamily(me, them) {
  if (me === 'freyr' && them === 'heimdall') return true
  if (me === 'heimdall' && them === 'tyr') return true
  if (me === 'tyr' && them === 'freyr') return true
  return false
}

export {
  hostileFamily
}
