/* eslint-disable react/jsx-filename-extension */
import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import store from './redux/store/index'
import App from './App'

console.log('%c\n ORD \n', 'background: #03181f; color: #bc0f53')

render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)
