/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import useSound from 'use-sound'

import { gameSettings } from '../conf/settings'

import { playRune } from '../redux/actions/index'
import { displayRuneEffect } from '../utils/effects'
import { hostileFamily } from '../utils/hostileFamily'
import { futhark } from '../conf/futhark'

import RuneSocket from './RuneSocket'

import '../css/rune.scss'

import sigilTyr from '../pictures/sigilTyr.png'
import sigilFreyr from '../pictures/sigilFreyr.png'
import sigilHeimdall from '../pictures/sigilHeimdall.png'
import hideTriesRune from '../pictures/effect_hideTriesRune.png'

import runePlay1 from '../sound/runePlay1.mp3'
import runePlay2 from '../sound/runePlay2.mp3'
import runePlay3 from '../sound/runePlay3.mp3'
import runePlay4 from '../sound/runePlay4.mp3'
import runePlay5 from '../sound/runePlay5.mp3'
import runePlayCursed from '../sound/runePlayCursed.mp3'
import runeCastReady from '../sound/runeReady.mp3'
import runeCancel1 from '../sound/runeCancel1.mp3'

/**
  * @desc Main rune button
*/

const propTypes = {
  type: PropTypes.string,
  currentSecret: PropTypes.string,
  hideLabel: PropTypes.bool,
  hideState: PropTypes.bool,
  xPosition: PropTypes.number,
  socket: PropTypes.bool,
  mode: PropTypes.oneOf(['add', 'delete', 'display'])
}

const defaultProps = {
  type: null,
  mode: 'add',
  hideLabel: false,
  hideState: false,
  xPosition: undefined,
  socket: false,
  currentSecret: null
}

const mapStateToProps = (state) => ({
  secret: state.secret,
  currentTry: state.currentTry,
  bestState: state.bestState,
  aett: state.aett,
  volume: state.settings.volume,
  playedCombos: state.playedCombos
})

function mapDispatchToProps(dispatch) {
  return {
    playRune: (payload) => dispatch(playRune(payload))
  }
}

function RuneButton({
  currentTry, secret, bestState, type, playRune, disabled, mode, socket,
  currentSecret, hideLabel, hideState, aett, xPosition, playedCombos, volume
}) {
  let action
  let sfx

  // Array of random default cast sounds
  const [castRuneSFX1] = useSound(runePlay1, { volume })
  const [castRuneSFX2] = useSound(runePlay2, { volume })
  const [castRuneSFX3] = useSound(runePlay3, { volume })
  const [castRuneSFX4] = useSound(runePlay4, { volume })
  const [castRuneSFX5] = useSound(runePlay5, { volume })
  const runePlaySFX = [castRuneSFX1, castRuneSFX2, castRuneSFX3, castRuneSFX4, castRuneSFX5]
  // Cursed rune SXF
  const [castRuneCursedSFX1] = useSound(runePlayCursed, { volume })
  // Take the rune back SFX
  const [cancelRuneSFX1] = useSound(runeCancel1, { volume })
  // Rune cast is ready SFX
  const [runeCastReadySFX] = useSound(runeCastReady, { volume })

  // Click action
  if (mode === 'add') {
    // Define action
    action = currentTry.length < secret.length && !currentTry.includes(type)
      ? (() => playRune({ mode: 'play', rune: type }))
      : undefined
    // Define sound effect : basic or rune ready
    const castRuneSFX = currentTry.length === secret.length - 1
      ? runeCastReadySFX
      : runePlaySFX[Math.floor(Math.random() * runePlaySFX.length)]
    // Cursed or not cursed
    sfx = (futhark[type]?.castEffect?.includes('runeCastCost') && hostileFamily(futhark[type]?.aett, aett))
      ? castRuneCursedSFX1
      : castRuneSFX
  } else if (mode === 'display') {
    action = undefined
  } else {
    action = () => playRune({ mode: 'delete', rune: type })
    sfx = cancelRuneSFX1
  }

  // Current secret state
  let state
  if (mode === 'display') {
    state = 'wrong'
    if (type === currentSecret) state = 'found'
    else if (secret.includes(type)) state = 'present'
  } else {
    // Best secret state
    if (bestState) {
      if (bestState[type] === 0) state = 'wrong'
      else if (bestState[type] === 1) state = 'present'
      else if (bestState[type] === 2) { state = 'found' }
    }
  }

  // Special secret state for reveal castEffect
  let isPresent = false
  if (futhark[type]?.castEffect?.includes('reveal') && mode === 'add') {
    for (let i = 0; i < secret.length; i += 1) {
      if (secret.includes(type)) isPresent = true
    }
  }

  // Cast effects
  const castEffects = futhark[type]?.castEffect?.join(' ')
  const buttonEffects = []
  if (futhark[type]?.castEffect) {
    for (let index = 0; index < futhark[type].castEffect.length; index += 1) {
      buttonEffects.push(displayRuneEffect(futhark[type].castEffect[index]))
    }
  }

  // Combo effect UI
  let currentCombo = 0
  if (currentTry) {
    for (let index = 0; index < currentTry.length; index += 1) {
      const rune = currentTry[index]
      if (futhark[rune].aett === aett && !playedCombos?.includes(rune)) currentCombo += 1
    }
  }

  const possibleBonus = mode === 'delete'
    && (aett === futhark[type]?.aett)
    && currentCombo < gameSettings.minimumCombo
    && !playedCombos?.includes(type)
    && secret.length >= gameSettings.minimumCombo

  const activeBonus = mode === 'delete'
    && (aett === futhark[type]?.aett)
    && currentCombo >= gameSettings.minimumCombo
    && !playedCombos?.includes(type)
    && secret.length >= gameSettings.minimumCombo

  const runeClasses = classNames({
    rune: true,
    [`${futhark[type]?.aett}`]: true,
    [`${castEffects}`]: castEffects,
    [`${state}`]: mode !== 'delete' && state && !hideState,
    [`glow_${aett}`]: possibleBonus,
    [`glow_${aett}_pulse`]: activeBonus,
    playedCombo: aett === futhark[type]?.aett && playedCombos?.includes(type),
    notPlayedCombo: mode !== 'demo' && aett === futhark[type]?.aett && !playedCombos?.includes(type) && secret.length >= gameSettings.minimumCombo,
    hostile: hostileFamily(futhark[type]?.aett, aett),
    isPresent: mode === 'add' && isPresent,
    hideLabel
  })

  const runeStyle = {
    transform: `rotate(${futhark[type]?.rotation}deg)`, // More  style={{}} feel for rune position
    animationDuration: `${futhark[type]?.glowDuration}s` // More natural feel for rune glow
  }

  const shimmerStyle = {
    animationDelay: `${futhark[type]?.shimmerDelay}s`,
    animationDuration: `${futhark[type]?.shimmerDuration}s`
  }

  // Additional aett sigil
  let sigil
  switch (futhark[type]?.aett) {
    case 'tyr':
      sigil = (
        <div
          key="sigil"
          className={`sigil tyr ${hostileFamily(futhark[type].aett, aett) ? 'hostile' : 'notHostile'}`}
          style={{ animationDuration: `${futhark[type]?.glowDuration}s` }}
        >
          <img src={sigilTyr} alt="Tyr" />
        </div>
      )
      break
    case 'freyr':
      sigil = (
        <div
          key="sigil"
          className={`sigil freyr ${hostileFamily(futhark[type].aett, aett) ? 'hostile' : 'notHostile'}`}
          style={{ animationDuration: `${futhark[type]?.glowDuration}s` }}
        >
          <img src={sigilFreyr} alt="Freyr" />
        </div>
      )
      break
    case 'heimdall':
      sigil = (
        <div
          key="sigil"
          className={`sigil heimdall ${hostileFamily(futhark[type].aett, aett) ? 'hostile' : 'notHostile'}`}
          style={{ animationDuration: `${futhark[type]?.glowDuration}s` }}
        >
          <img src={sigilHeimdall} alt="Heimdall" />
        </div>
      )
      break
    default:
      break
  }

  const wrapperStyle = typeof (xPosition) !== 'undefined' ? {
    position: 'absolute',
    top: 0,
    left: `${xPosition}px`
  } : {}

  // Display component
  return (
    <div key="runeWrapper" className="runeWrapper" style={wrapperStyle}>
      <TransitionGroup key="runeWrapperTransitionGroup" component={null}>
        {!socket
        && (
          <CSSTransition
            key={`${type}_rune`}
            timeout={400}
            classNames={mode === 'delete' ? 'runePlayed_cast' : 'runePlayed'}
          >
            <div className="buttonWrapper">
              <button
                className={runeClasses}
                type="button"
                disabled={disabled}
                style={runeStyle}
                onClick={action}
                onMouseDown={sfx}
              >
                <div className="shimmer" style={shimmerStyle} />
                {futhark[type].castEffect?.includes('hidden') && <div className="specialRune runeSmoke" />}
                {futhark[type].castEffect?.includes('joker') && <div className="specialRune runeSky" />}
                {activeBonus && <div key="buttonEffects" className="buttonEffects bonus">{displayRuneEffect('bonus')}</div>}
                {(
                  hostileFamily(futhark[type]?.aett, aett)
                  && buttonEffects.filter(Boolean).length > 0
                )
                  && <div key="buttonEffects" className="buttonEffects">{buttonEffects}</div>}
                {!hideLabel ? futhark[type]?.letter : <img className="hideTriesRune" src={hideTriesRune} alt="Hid tries" />}
                {((aett === futhark[type]?.aett || hostileFamily(futhark[type]?.aett, aett)) || mode === 'demo') && sigil}
              </button>
            </div>
          </CSSTransition>
        )}
        {socket
          && <RuneSocket key={`${type}_rune_socket`} />}
      </TransitionGroup>
    </div>
  )
}

// Applying propTypes definition and default values
RuneButton.propTypes = propTypes
RuneButton.defaultProps = defaultProps

// Exporting as default
export default connect(mapStateToProps, mapDispatchToProps)(RuneButton)
