/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
// Utils
function resetRound(data) {
  data.round = 0
  data.triesCount = 0
  data.runeCastCost = 0
  data.tries = [[]]
  data.currentTry = []
  if (data.bestState) delete data.bestState
  if (data.gameOver) delete data.gameOver
  if (data.previousEnergy) delete data.previousEnergy
  if (data.energyVariation) delete data.energyVariation
  data.runeSecretFound = false

  return data
}

function diceRoll(sides) {
  return Math.floor(Math.random() * sides) + 1
}

function randomProperty(obj) {
  const keys = Object.keys(obj)
  return obj[keys[keys.length * Math.random() << 0]]
}

function randomKey(obj) {
  const keys = Object.keys(obj)
  return keys[keys.length * Math.random() << 0]
}

function randomValue(array) {
  return array[Math.floor(Math.random() * array.length)]
}

function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

function limitValue(value, min, max) {
  if (value > max) value = max
  if (value < min) value = min
  return value
}

const sumOfArray = (arr) => arr.reduce((a, b) => a + b, 0)

function randomBetween(min, max) {
  if (min < 0) {
    return min + Math.random() * (Math.abs(min) + max)
  }
  return min + Math.random() * max
}

function generateWeight(items, weight) {
  const weighedItems = []
  let currentItem = 0
  let i

  while (currentItem < items.length) {
    for (i = 0; i < weight[currentItem]; i++) { weighedItems[weighedItems.length] = items[currentItem] }
    currentItem++
  }

  return weighedItems
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

function clog(message, mode = 'default') {
  let color; let
    icon
  switch (mode) {
    case 'location':
      color = 'grey'
      icon = '📍'
      break
    case 'action':
      color = 'red'
      icon = '🟥'
      break
    case 'reducer':
      color = 'orange'
      icon = '🟧'
      break
    case 'function':
      color = 'grey'
      icon = '🕹'
      break
    case 'stop':
      color = 'red'
      icon = '👋🏼'
      break
    case 'error':
      color = 'Red'
      break
    case 'warning':
      color = 'Orange'
      break
    default:
      color = 'white'
      break
  }

  if (mode === 'data') console.table(message)
  else console.log(`%c${icon} ${message}`, `color:${color}`)
}

function shuffle(array) {
  let currentIndex = array.length

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    const randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]]
  }
}

const moveItem = (array, to, from) => {
  const item = array[from]
  array.splice(from, 1)
  array.splice(to, 0, item)
  return array
}

export {
  resetRound,
  diceRoll,
  randomProperty,
  randomKey,
  randomValue,
  getRandomInt,
  limitValue,
  sumOfArray,
  generateWeight,
  clog,
  capitalizeFirstLetter,
  randomBetween,
  shuffle,
  moveItem
}
