/* eslint-disable no-param-reassign */

/**
  * @desc Set name
*/

const name = (data, nameValue) => {
  const { } = data

  data.settings.name = nameValue

  return data
}

export { name }
