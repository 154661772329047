/* eslint-disable react/prop-types */
import React from 'react'
import { connect } from 'react-redux'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import RuneButton from './RuneButton'

import '../css/runeGrid.scss'

const mapStateToProps = (state) => ({
  secret: state.secret,
  tries: state.tries,
  addEffect: state.addEffect
})

function RuneGrid({
  tries, secret, addEffect
}) {
  const grid = []

  // Global effects
  const hideTriesRune = addEffect?.includes('hideTriesRune')
  const hideTriesState = addEffect?.includes('hideTriesState')

  if (tries[0].length > 0) {
    for (let i = 0; i < tries.length; i++) {
      const line = []
      // Here compute display (valid, no valid, etc) + effects
      for (let j = 0; j < secret.length; j++) {
        line.push(
          <RuneButton
            key={`rune_${i}_${j}`}
            currentSecret={secret[j]}
            type={tries[i][j]}
            hideLabel={hideTriesRune && i !== tries.length - 1}
            hideState={hideTriesState && i !== tries.length - 1}
            mode="display"
          />
        )
      }
      grid.push(
        <CSSTransition
          key={`grid_line_${i}`}
          timeout={600}
          classNames="runeGrid gridline"
        >
          <div key={i}>{line}</div>
        </CSSTransition>
      )
    }
  }
  // Display component
  return (
    <div className="runeGridContainer">
      <TransitionGroup component={null}>
        {grid}
      </TransitionGroup>
    </div>
  )
}

// Exporting as default
export default connect(mapStateToProps)(RuneGrid)
