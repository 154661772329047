import React from 'react'
import { connect } from 'react-redux'

import { playRune } from '../redux/actions/index'

import RuneButton from './RuneButton'

import '../css/runePool.scss'

/**
  * @desc Wrapper for all the possible runes
*/

const mapStateToProps = (state) => ({
  runePool: state.runePool,
  currentTry: state.currentTry,
  runeSecretFound: state.runeSecretFound,
  gameOver: state.gameOver,
  addEffect: state.addEffect,
  aett: state.aett
})

function mapDispatchToProps(dispatch) {
  return {
    playRune: (payload) => dispatch(playRune(payload))
  }
}

function RunePool({
  runePool, currentTry, runeSecretFound, gameOver, addEffect, aett
}) {
  // Global effects
  const hidePoolState = addEffect?.includes('hidePoolState')

  const buttons = runePool.map((rune) => (
    <RuneButton
      key={rune}
      type={rune}
      mode="add"
      hideState={hidePoolState}
      socket={currentTry.includes(rune)}
    />

  ))

  // Display component
  // if (!runeSecretFound && !gameOver) {
  return (
    <div className="columnWrapper">
      <div className="runePoolWrapper">
        <div className={`runePool ${(runeSecretFound || gameOver) ? 'hidden' : 'visible'} ${aett}`}>
          {buttons}
        </div>
      </div>
    </div>
  )
  // }
  // return null
}

// Exporting as default
export default connect(mapStateToProps, mapDispatchToProps)(RunePool)
