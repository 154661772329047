/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { connect } from 'react-redux'

import { setGameState, settings } from '../redux/actions/index'

import { futhark } from '../conf/futhark'

import '../css/runeCloud.scss'

/**
  * @desc Animated rune cloud
*/

const mapStateToProps = (state) => ({
  state: state.state
})

function mapDispatchToProps(dispatch) {
  return {
    setGameState: (payload) => dispatch(setGameState(payload)),
    settings: (payload) => dispatch(settings(payload))
  }
}

function NewRune({
  state
}) {
  function generateRunes() {
    // Random styling
    function runeStyle(type) {
      const distance = futhark[type]?.distance

      return {
        left: `${futhark[type]?.leftPosition}%`,
        top: `${futhark[type]?.topPosition}%`,
        animationDelay: `${futhark[type]?.shimmerDelay}s`,
        animationDuration: `${futhark[type]?.shimmerDuration}s`,
        transform: `scale(${distance / 70 + 0.2})`,
        zIndex: distance
      }
    }

    return Object.keys(futhark).map((rune) => (
      <div
        key={`rune_button_demo_${rune}`}
        className="demoRuneWrapper"
        style={runeStyle(rune)}
        data-distance={futhark[rune]?.distance / 25}
      >
        <div className="runeText">
          {futhark[rune].letter}
        </div>
      </div>
    ))
  }

  return (
    <div>
      {generateRunes()}
    </div>
  )
}

// Exporting as default
export default connect(mapStateToProps, mapDispatchToProps)(NewRune)
