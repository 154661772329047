/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/prop-types */
import React from 'react'
import { connect } from 'react-redux'

import sigilTyr from '../pictures/sigilTyr.png'
import sigilFreyr from '../pictures/sigilFreyr.png'
import sigilHeimdall from '../pictures/sigilHeimdall.png'

/**
  * @desc Simple title block
*/

const mapStateToProps = (state) => ({

})

function Separator({
}) {
  return (
    <div className="separator">
      <img src={sigilTyr} />
      <img src={sigilFreyr} />
      <img src={sigilHeimdall} />
    </div>
  )
}

// Exporting as default
export default connect(mapStateToProps)(Separator)
