import _, { indexOf, sample } from 'underscore'
import Rand from 'rand-seed'
import { futhark } from '../conf/futhark'
// Utils
import { moveItem } from './utils'

function generateSaga(seed) {
  const rand = new Rand(seed)
  // Generate base array
  const runesArray = Object.keys(futhark)
  const baseArray = Array.from({ length: runesArray.length }, () => rand.next())
  const sortedArray = [...baseArray].sort()

  const finalArray = []
  for (let index = 0; index < baseArray.length; index++) {
    const element = baseArray[index]
    finalArray.push(runesArray[sortedArray.indexOf(element)])
  }

  // Move Odin's rune
  if (finalArray.indexOf('othala') < futhark.othala.startLevel) {
    moveItem(finalArray, futhark.othala.startLevel, finalArray.indexOf('othala'))
  }
  // Move Loki's rune
  if (finalArray.indexOf('hagalaz') < futhark.hagalaz.startLevel) {
    moveItem(finalArray, futhark.hagalaz.startLevel, finalArray.indexOf('hagalaz'))
  }

  // return [
  //   'perthro',
  //   'thurisaz',
  //   'gebo',
  //   'othala',
  //   'hagalaz',
  //   'ansuz',
  //   'eihwaz',
  //   'uruz',
  //   'wunjo',
  //   'nauthiz',
  //   'algiz',
  //   'mannaz',
  //   'fehu',
  //   'ehwaz',
  //   'laguz',
  //   'jera',
  //   'dagaz',
  //   'raidho',
  //   'ingwaz',
  //   'isaz',
  //   'kenaz',
  //   'sowilo',
  //   'tiwaz',
  //   'berkana'
  // ]

  return finalArray
}

function generateRunePool(runeSaga, length) {
  return runeSaga.slice(0, length)
}

function generateSecret(pool, length, level, seed) {
  const rand = new Rand(seed + level)

  const runesArray = [...pool]
  const baseArray = Array.from({ length: runesArray.length }, () => rand.next())
  const sortedArray = [...baseArray].sort()

  const finalArray = []
  for (let index = 0; index < baseArray.length; index++) {
    const element = baseArray[index]
    finalArray.push(runesArray[sortedArray.indexOf(element)])
  }

  return finalArray.slice(0, length)
}

function addRune(runePool, runeSaga) {
  // Get current position in the saga, +1
  const [lastRune] = runePool.slice(-1)
  return runeSaga[runeSaga.indexOf(lastRune) + 1]
}

export {
  generateSaga,
  generateRunePool,
  generateSecret,
  addRune
}
