/**
  * @desc Starting a game round
*/

const battle = (data, bonus) => {
  // Add initial bonus
  switch (bonus) {
    case 'heimdall':
      data.skogarmaor = 1
      break
    case 'tyr':
      data.vegvisir = 1
      break
    case 'freyr':
      data.yggdrasil = 1
      break
    case 'njord':
      data.njord = 1
      break
    default:
      break
  }
  // Set initial aett
  // data.aett = bonus
  // Visual effect
  data.flash = 1
  // Set game state
  data.state = 'battle'

  return data
}

export { battle }
