/* eslint-disable no-plusplus */
/* eslint-disable react/prop-types */
import React from 'react'
import { connect } from 'react-redux'

import '../css/explanations.scss'
import { wording } from '../wording/wording'

const mapStateToProps = (state) => ({
  secret: state.secret,
  tries: state.tries,
  language: state.settings.language
})

function RuneGrid({
  tries, secret, language
}) {
  const results = {}
  results.present = 0
  results.found = 0
  results.wrong = 0

  // We have at least played a round
  if (tries[0].length > 0) {
    for (let j = 0; j < secret.length; j++) {
      const type = tries[tries.length - 1][j]
      const currentSecret = secret[j]
      if (type === currentSecret) results.found++
      else if (secret.includes(type)) results.present++
      else results.wrong++
    }
  }

  function displayResults(items) {
    return Object.keys(items).map((item) => (items[item] > 0
      ? (
        <div className={`explanation ${item}`}>
          <span>{items[item]}</span>
          {items[item] === 1 ? wording[language].results[item] : wording[language].results[`${item}Plural`]}
        </div>
      )
      : null
    ))
  }

  // Display component
  return (
    <div className="castExplanation">
      {(results.wrong === 0
      && results.found === 0
      && results.present === 0)
        ? wording[language].results.start
        : displayResults(results)}
    </div>
  )
}

// Exporting as default
export default connect(mapStateToProps)(RuneGrid)
