/* eslint-disable no-param-reassign */

/**
  * @desc Score is submitted
*/

const submitScore = (data) => {
  const { } = data

  data.submitScore = true

  return data
}

export { submitScore }
