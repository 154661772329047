/* eslint-disable no-restricted-syntax */
import { getRandomInt } from '../utils/utils'

// Runes definition
const futhark = {
  fehu: {
    letter: 'F',
    startLevel: 0,
    aett: 'freyr',
    addEffect: ['longerSecretPlus', 'removeEffects']
  },
  uruz: {
    letter: 'U',
    startLevel: 0,
    aett: 'freyr',
    addEffect: ['longerSecret', 'outCast'],
    castEffect: ['runeCastCost']
  },
  thurisaz: {
    letter: 'T',
    startLevel: 0,
    aett: 'freyr',
    addEffect: ['longerSecret', 'removeEffects']
  },
  ansuz: {
    letter: 'A',
    startLevel: 0,
    aett: 'freyr',
    addEffect: ['longerSecret', 'freeCast'],
    castEffect: ['runeCastCost', 'reveal']
  },
  raidho: {
    letter: 'R',
    startLevel: 0,
    aett: 'freyr',
    addEffect: ['longerSecret', 'removeEffects']
  },
  kenaz: {
    letter: 'C',
    startLevel: 0,
    aett: 'freyr',
    addEffect: ['longerSecretPlus', 'outCast'],
    castEffect: ['runeCastCost']
  },
  gebo: {
    letter: 'G',
    startLevel: 0,
    aett: 'freyr',
    addEffect: ['longerSecret', 'increaseVictoryEnergy']
  },
  wunjo: {
    letter: 'W',
    startLevel: 0,
    aett: 'freyr',
    addEffect: ['longerSecretPlus', 'freeCast']
  },
  hagalaz: {
    letter: 'H',
    startLevel: 12,
    aett: 'loki',
    addEffect: ['longerSecret', 'hideTriesState', 'hideTriesRune'],
    castEffect: ['hidden']
  },
  nauthiz: {
    letter: 'n',
    startLevel: 0,
    aett: 'heimdall',
    addEffect: ['hideTriesState']
  },
  isaz: {
    letter: 'i',
    startLevel: 0,
    aett: 'heimdall',
    addEffect: ['increaseCastCost'],
    castEffect: ['runeCastCost']
  },
  jera: {
    letter: 'J',
    startLevel: 0,
    aett: 'heimdall',
    addEffect: ['increaseCastCost'],
    castEffect: ['reveal']
  },
  eihwaz: {
    letter: 'I',
    startLevel: 0,
    aett: 'heimdall',
    addEffect: ['hidePoolState'],
    castEffect: ['runeCastCost']
  },
  perthro: {
    letter: 'P',
    startLevel: 0,
    aett: 'heimdall',
    addEffect: ['hidePoolState']
  },
  algiz: {
    letter: 'Z',
    startLevel: 0,
    aett: 'heimdall',
    addEffect: ['hideTriesRune']
  },
  sowilo: {
    letter: 'S',
    startLevel: 0,
    aett: 'heimdall',
    addEffect: ['hideTriesRune']
  },
  tiwaz: {
    letter: 't',
    startLevel: 0,
    aett: 'tyr',
    addEffect: ['freeCast'],
    castEffect: ['runeCastCost']
  },
  berkana: {
    letter: 'B',
    startLevel: 0,
    aett: 'tyr',
    addEffect: ['removeEffects']
  },
  ehwaz: {
    letter: 'E',
    startLevel: 0,
    aett: 'tyr',
    addEffect: ['removeEffects']
  },
  mannaz: {
    letter: 'M',
    startLevel: 0,
    aett: 'tyr',
    addEffect: ['shorterSecret', 'outCast'],
    castEffect: ['runeCastCost']
  },
  laguz: {
    letter: 'L',
    startLevel: 0,
    aett: 'tyr',
    addEffect: ['shorterSecret', 'freeCast'],
    castEffect: ['reveal']
  },
  ingwaz: {
    letter: 'N',
    startLevel: 0,
    aett: 'tyr',
    addEffect: ['shorterSecret', 'outCast'],
    castEffect: ['runeCastCost']
  },
  dagaz: {
    letter: 'D',
    startLevel: 0,
    aett: 'tyr',
    addEffect: ['removeEffects']
  },
  othala: {
    letter: 'O',
    startLevel: 6,
    aett: 'odin',
    addEffect: ['shorterSecret', 'increaseVictoryEnergy'],
    castEffect: ['joker']
  }
}

// Pseudo-random properties for natural feels
for (const [key] of Object.entries(futhark)) {
  futhark[key].rotation = Math.ceil(Math.random() * 4) * (Math.round(Math.random()) ? 1 : -1)
  futhark[key].glowDuration = Math.random() * (3 - 2) + 2
  futhark[key].shimmerDelay = Math.random() * (3 - 2) + 2
  futhark[key].shimmerDuration = getRandomInt(10, 20)
  futhark[key].leftPosition = getRandomInt(0, 100)
  futhark[key].topPosition = getRandomInt(0, 100)
  futhark[key].distance = getRandomInt(0, 100)
}

export { futhark }
