/* eslint-disable react/no-unknown-property */
/* eslint-disable react/prop-types */
import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'

import { setGameState, settings } from '../redux/actions/index'

import freyrCharacter from '../pictures/character_freyr.png'
import tyrCharacter from '../pictures/character_tyr.png'
import heimdallCharacter from '../pictures/character_heimdall.png'
import odinCharacter from '../pictures/character_odin.png'
import lokiCharacter from '../pictures/character_loki.png'

import sigilTyr from '../pictures/sigilTyr.png'
import sigilFreyr from '../pictures/sigilFreyr.png'
import sigilHeimdall from '../pictures/sigilHeimdall.png'

import '../css/characters.scss'

/**
  * @desc Characters
*/

const mapStateToProps = (state) => ({
  state: state.state,
  aett: state.aett
})

function mapDispatchToProps(dispatch) {
  return {
    setGameState: (payload) => dispatch(setGameState(payload)),
    settings: (payload) => dispatch(settings(payload))
  }
}

const BattleCharacters = function ({
  aett, state
}) {
  const characterWrapperClasses = classNames({
    characterWrapper: true,
    small: state === 'battle',
    tyr: aett === 'tyr',
    freyr: aett === 'freyr',
    heimdall: aett === 'heimdall',
    odin: aett === 'odin',
    loki: aett === 'loki'
  })

  return (
    <div className={characterWrapperClasses}>
      <div className={`aettName ${(aett === 'freyr') ? 'visible' : 'hidden'}`}>
        <img src={sigilFreyr} alt="Freyr" />
        Freyr
        <img src={sigilFreyr} alt="Freyr" />
      </div>
      <div className={`aettName ${(aett === 'tyr') ? 'visible' : 'hidden'}`}>
        <img src={sigilTyr} alt="Tyr" />
        Tyr
        <img src={sigilTyr} alt="Tyr" />
      </div>
      <div className={`aettName ${(aett === 'heimdall') ? 'visible' : 'hidden'}`}>
        <img src={sigilHeimdall} alt="Heimdall" />
        Heimdall
        <img src={sigilHeimdall} alt="Heimdall" />
      </div>
      <div className={`aettName ${(aett === 'odin') ? 'visible' : 'hidden'}`}>Odin</div>
      <div className={`aettName ${(aett === 'loki') ? 'visible' : 'hidden'}`}>Loki</div>
      <img className={`left freyr ${(aett === 'freyr') ? 'visible' : 'hidden'}`} src={freyrCharacter} alt="" />
      <img className={`left tyr ${(aett === 'tyr') ? 'visible' : 'hidden'}`} src={tyrCharacter} alt="" />
      <img className={`left heimdall ${(aett === 'heimdall') ? 'visible' : 'hidden'}`} src={heimdallCharacter} alt="" />
      <img className={`left odin ${(aett === 'odin') ? 'visible' : 'hidden'}`} src={odinCharacter} alt="" />
      <img className={`left loki ${(aett === 'loki') ? 'visible' : 'hidden'}`} src={lokiCharacter} alt="" />
    </div>
  )
}

// Exporting as default
export default connect(mapStateToProps, mapDispatchToProps)(BattleCharacters)
