/* eslint-disable no-param-reassign */

/**
  * @desc Display tooltip
*/

const preference = (data, type, value) => {
  const { } = data

  data.settings[type] = value

  return data
}

export { preference }
