import React from 'react'
import { connect } from 'react-redux'

import { setGameState } from '../redux/actions/index'
import { clog } from '../utils/utils'

import NewRune from '../Ui/NewRune'

import '../css/app.scss'

const mapStateToProps = (state) => ({
  state: state.state
})

function mapDispatchToProps(dispatch) {
  return {
    setGameState: (payload) => dispatch(setGameState(payload))
  }
}

const Victory = function ({ setGameState, state }) {
  clog('Next round', 'location')

  return (
    <div className="mainWrapper">
      <div className="appWrapper">
        <NewRune />
      </div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Victory)
